<template>
  <div class="login-buttons-web  my-4">
    <BaseButton
      v-for="loginButton in loginButtons"
      :key="loginButton.name"
      outlined
      x-large
      color="#e2e8f0"
      :disabled="isDisabled"
      :class="loginButton.class"
      @click="handleClick(loginButton)"
    >
      <BaseSpriteIcon
        :custom-class="loginButton.icon"
        :icon-name="loginButton.icon"
      />
    </BaseButton>
  </div>
</template>

<script>
import LoginActionsEnum from '@/enums/LoginActionsEnum';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseButton from '@/components/base/BaseButton/BaseButton';
import { mapActions } from 'vuex';

export default {
  name: 'LoginButtons',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  props: {
    externalData: Object
  },
  data() {
    let loginButtons = [
      {
        name: this.$t('App.Login.facebook.label'),
        class: 'facebook-button',
        handler: this.loginByFacebook,
        actionType: LoginActionsEnum.LOGIN_BY_FACEBOOK,
        icon: 'ico-pic-fb'
      },
      {
        name: this.$t('App.Login.googlePlus.label'),
        class: 'google-button',
        handler: this.loginByGoogle,
        actionType: LoginActionsEnum.LOGIN_BY_GMAIL,
        icon: 'ico-pic-google'
      }
    ];

    const isIos = this.$store.getters['ContextStore/isIos'];
    const isBrowser = !this.$store.getters['ContextStore/isDevice'];

    if (isIos || isBrowser) {
      loginButtons.push({
        name: this.$t('App.Login.Apple.label'),
        class: 'apple-button',
        handler: this.loginByApple,
        actionType: LoginActionsEnum.LOGIN_BY_APPLE,
        icon: 'ico-portal-ios'
      });
    }

    return {
      isDisabled: false,
      loginButtons
    };
  },
  computed: {
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    }
  },
  methods: {
    ...mapActions('LoginStore', [
      'loginByGoogle',
      'loginByFacebook',
      'loginByApple'
    ]),
    handleClick(loginButton) {
      if (!this.isOnline) {
        this.$store.dispatch('ManagePopupStore/openErrorToaster', {
          text: this.$t('Toaster.noInternetConnection.error.text'),
          toasterIcon: 'ico-no-wifi'
        });
        return;
      }
      this.$_socialButtonClick();
      loginButton.handler();
      this.isDisabled = true;

      const BUTTON_DISABLE_TIME = 3_000;
      setTimeout(() => {
        this.isDisabled = false;
      }, BUTTON_DISABLE_TIME);
    },
    $_socialButtonClick() {
      this.$emit('onAction', {
        type: 'socialButtonClick'
      });
    }
  }
};
</script>

<style src="./LoginButtonsWeb.less" lang="less" scoped></style>
