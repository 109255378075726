import Utils from '@/services/utils/Utils';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

export default {
  data() {
    return {
      htmlClassList: this.$store.getters[
        'ContextStore/getPlatformHtmlClassList'
      ]
    };
  },
  created() {
    this.$store.dispatch('LibraryStore/transformToClientData');
  },
  head() {
    const headSettings = this.getCommonHeadSettings();
    return headSettings;
  },
  methods: {
    getCommonHeadSettings() {
      const canonicalLinkSetting = this.$store.getters[
        'ContextStore/getPageCanonicalLinkSetting'
      ](this.$route.name);
      const classes = this.getCommonPageClasses();
      const systemLang = this.$store.getters['ContextStore/getSystemLanguage'];
      return {
        link: [canonicalLinkSetting],
        htmlAttrs: {
          lang: systemLang,
          class: [
            ...this.htmlClassList,
            AppConstantsUtil.MANAGE_PUBLICATION_SCROLL_CLASS
          ]
        },
        bodyAttrs: {
          class: classes,
          dir: Utils.getDirection(systemLang)
        }
      };
    },
    getCommonPageClasses() {
      const brandClass = this.$store.getters['ContextStore/getBrandClass'];
      const platformClasses = this.$store.getters[
        'ContextStore/getPlatformBodyClassList'
      ];
      return [brandClass, ...platformClasses];
    }
  }
};
