<template>
  <div class="compilation-empty-placeholder pb-10">
    <div class="pic-block">
      <img :src="picOpenBook" alt="Open book" />
    </div>
    <h3>You have not added any quotes yet.</h3>
    <p>
      To add selections:<br />
      Open any book -> Select text inside<br />
      paragraph -> Choose “Extract” icon
    </p>
    <BackToLibraryControl x-large :text="false" color="primary" elevation="0"
      ><template #back-to-library>{{
        $t('CongratulationPopup.popup.button')
      }}</template></BackToLibraryControl
    >
  </div>
</template>

<script>
import BackToLibraryControl from '@/components/controls/BackToLibraryControl/BackToLibraryControl';
import images from '@/assets/images';

export default {
  name: 'CompilationEmptyPlaceholder',
  components: { BackToLibraryControl },
  data() {
    return {
      picOpenBook: images.picOpenBook
    };
  }
};
</script>

<style lang="less" src="./CompilationEmptyPlaceholder.less"></style>
