<template>
  <div class="suggested-wrapper" @click.stop>
    <Category
      :level-label="levelLabel"
      :category-name="categoryName"
      :publications="suggestedBooks"
    />
  </div>
</template>

<script>
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';

import publicationUtils from '@/services/utils/publicationUtils';

import Category from '@/components/views/LibraryView/Category.vue';

export default {
  name: 'SuggestedBooks',
  components: {
    Category
  },
  data() {
    return {
      categoryName: PublicationsTypesEnum.SUGGESTED_BOOK
    };
  },
  computed: {
    levelLabel() {
      return publicationUtils.getPubsLevelRangesLabel(
        this.suggestedBooks,
        this.$t.bind(this)
      );
    },
    suggestedBooks() {
      const publications = this.$store.getters[
        'LibraryStore/getSuggestedBooks'
      ];
      const sortStrategy = this.$store.getters[
        'ContextStore/librarySortStrategy'
      ];
      const sortedPubs = publicationUtils.sortPublication(
        publications,
        sortStrategy
      );
      return sortedPubs;
    }
  }
};
</script>

<style lang="less" src="./SuggestedBooks.less"></style>
