<template>
  <div class="loginform-content login-by-email">
    <div class="loginform-fields">
      <BaseTextField
        v-model.trim="$v.loginEmail.$model"
        class="mt-4"
        :class="{ invalid: isFieldInvalid($v.loginEmail) }"
        :label="$t('Email')"
        :placeholder="$t('Email.placeholder')"
        type="email"
        tabindex="0"
        name="loginEmail"
        prepend-inner-icon="$icoEnvelop"
        :error-messages="$t(validationOnLogin.email.errorMessage)"
        :maxlength="emailMaxLength"
        @input="onInput"
        @keydown.enter="onLogin"
      />
      <BaseTextField
        v-model="$v.loginPassword.$model"
        class="loginform-field"
        :class="{ invalid: isFieldInvalid($v.loginPassword) }"
        :label="$t('Password')"
        :placeholder="$t('Password')"
        :type="passwordType"
        name="loginPassword"
        prepend-inner-icon="$icoLocked"
        :append-icon="passwordShowIcon"
        :error-messages="
          !validationOnLogin.password.isValid
            ? $t(validationOnLogin.password.errorMessage)
            : !validationOnLogin.combination.isValid
            ? $t(validationOnLogin.combination.errorMessage)
            : !areCredentialsValid.status
            ? $t(areCredentialsValid.errorMessage)
            : null
        "
        :maxlength="passwordMaxLength"
        @input="onInput"
        @keydown.enter="onLogin"
        @click:append="changePasswordType"
      />
      <div class="text-center">
        <BaseButton color="primary" text @click="resetPassword">
          {{ $t('Login.forgotpassword.label') }}
        </BaseButton>
      </div>
    </div>
    <div class="loginform-controls mt-2">
      <div @click="onLogin">
        <BaseButton
          ref="loginButton"
          class="text-none"
          color="primary"
          block
          x-large
          elevation="0"
        >
          {{ $t('SignIn') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import LoginActionsEnum from '@/enums/LoginActionsEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import { required, email } from 'vuelidate/lib/validators';
import ValidationErrorMessages from '@/enums/ValidationErrorMessagesEnum';

import AppModes from '@/enums/AppModeEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField.vue';
import {
  isWithoutSpaces,
  emailMaxLength,
  passwordMaxLength
} from '@/services/ValidationService';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginByEmail',
  components: {
    BaseButton,
    BaseTextField
  },
  data() {
    const appMode = this.$store.getters['ContextStore/appModeGetter'];
    return {
      appMode,
      validationOnLogin: {
        email: {
          isValid: true,
          errorMessage: ''
        },
        password: {
          isValid: true,
          errorMessage: ''
        },
        combination: {
          isValid: true,
          errorMessage: ValidationErrorMessages.COMBINATION_IS_INVALID
        }
      },
      areCredentialsValid: {
        status: true,
        errorMessage: 'LoginError.invalidCredentials'
      },
      passwordMaxLength,
      emailMaxLength,
      isPasswordShown: false
    };
  },
  validations: {
    loginEmail: {
      required,
      email
    },
    loginPassword: {
      required,
      isWithoutSpaces
    }
  },
  computed: {
    ...mapGetters('ContextStore', ['isRegistrationAllowed', 'isDevice']),
    loginEmail: {
      set(val) {
        this.$store.commit('LoginStore/setLoginEmail', val);
      },
      get() {
        return this.$store.getters['LoginStore/getLoginEmail'];
      }
    },
    loginPassword: {
      set(val) {
        this.$store.commit('LoginStore/setLoginPassword', val);
      },
      get() {
        return this.$store.getters['LoginStore/getLoginPassword'];
      }
    },
    passwordShowIcon() {
      return this.isPasswordShown ? '$hidePasswordIcon' : '$showPasswordIcon';
    },
    passwordType() {
      return this.isPasswordShown ? 'text' : 'password';
    },
    shouldShowSignUp() {
      return this.isRegistrationAllowed && this.appMode !== AppModes.EDITOR;
    }
  },
  mounted() {
    this.$store.dispatch('LoginStore/cleanLoginInfo');
  },
  methods: {
    changePasswordType() {
      this.isPasswordShown = !this.isPasswordShown;
    },
    resetPassword() {
      this.$emit('onAction', {
        type: LoginActionsEnum.TO_FORGOT_PASSWORD
      });
    },
    goToRegistration() {
      this.$emit('onAction', {
        type: LoginActionsEnum.TO_REGISTRATION
      });
    },
    async onLogin() {
      this.$_resetLazyValidation();
      if (!this.$_isValidOnLogin()) {
        return Promise.resolve();
      }

      try {
        const isLoggedIn = await this.$store.dispatch(
          'LoginStore/loginByEmail'
        );
        const appState = this.$store.getters['ContextStore/appState'];
        this.$emit('onAction', {
          type: LoginActionsEnum.LOGIN_BY_EMAIL,
          data: {
            isLoggedIn
          }
        });
        if (isLoggedIn && appState === AppStateEnum.VERIFY_EMAIL) {
          this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
        }
        this.$store.dispatch('PaymentsStore/applyPromoCode');
      } catch (error) {
        if (this.isDevice && !this.isRegistrationAllowed) {
          this.$store.dispatch('LoginStore/handleAuthResult', {
            status: LoginActionsEnum.LOGIN_BY_EMAIL_ERROR
          });
        } else {
          this.validationOnLogin.combination.isValid = false;
          this.validationOnLogin.combination.errorMessage =
            ValidationErrorMessages.COMBINATION_IS_INVALID;
        }
      }
    },
    onInput() {
      this.areCredentialsValid.status = true;
      this.$_resetLazyValidation();
    },
    isFieldInvalid(fieldValidator) {
      return fieldValidator.$invalid && fieldValidator.$dirty;
    },
    $_isValidOnLogin() {
      const validEmail = this.$v.loginEmail;
      const validPassword = this.$v.loginPassword;
      validEmail.$touch();
      validPassword.$touch();
      if (validEmail.$invalid || validPassword.$invalid) {
        if (validEmail.$invalid) {
          this.validationOnLogin.email.isValid = false;
          this.validationOnLogin.email.errorMessage = !validEmail.required
            ? ValidationErrorMessages.EMAIL_IS_EMPTY
            : ValidationErrorMessages.EMAIL_IS_INVALID;
        }
        if (validPassword.$invalid) {
          this.validationOnLogin.password.isValid = false;
          this.validationOnLogin.password.errorMessage = !validPassword.required
            ? ValidationErrorMessages.PASSWORD_IS_EMPTY
            : ValidationErrorMessages.PASSWORD_IS_INCORRECT;
        }
        return false;
      }
      return true;
    },
    $_resetLazyValidation() {
      this.validationOnLogin.email.isValid = true;
      this.validationOnLogin.password.isValid = true;
      this.validationOnLogin.combination.isValid = true;
    }
  }
};
</script>

<style src="./LoginByEmail.less" lang="less"></style>
