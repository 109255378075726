<template>
  <client-only>
    <BaseButton
      v-if="isLoggedIn"
      elevation="0"
      x-large
      nuxt
      :color="color"
      class="trial-button px-6"
      @click="openLibrary"
      >Open library</BaseButton
    >
    <BaseButton
      v-else
      elevation="0"
      :color="color"
      x-large
      class="trial-button align-self-center px-6"
      @click="scrollToAnchor"
      >Get started</BaseButton
    >
  </client-only>
</template>

<script>
import AppStateEnum from '@/enums/AppStateEnum';

import BaseButton from '@/components/base/BaseButton/BaseButton.vue';

export default {
  name: 'TrialButton',
  components: {
    BaseButton
  },
  props: {
    color: String
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['UserStore/isLoggedIn'];
    }
  },
  methods: {
    openLibrary() {
      this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
    },
    scrollToAnchor() {
      this.$emit('controlEvent', {
        type: 'scrollToAnchor'
      });
    }
  }
};
</script>

<style></style>
