<template>
  <div
    v-if="isVisible"
    ref="controls"
    class="compilation-controls"
    :class="selector"
  >
    <div class="control-block">
      <div>
        <button @click="moveUp">
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        </button>
        <button @click="moveDown">
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        </button>
      </div>
      <div>
        <button
          :id="selector"
          class="hidden-controls"
          @click="openExtendedMenu"
        >
          <BaseSpriteIcon icon-name="ico-hidden-menu" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import CompilationEventsEnum from '@/enums/CompilationEventsEnum';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  components: {
    BaseSpriteIcon
  },
  props: {
    bookId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      paraId: '',
      selector: ''
    };
  },
  computed: {
    isVisible() {
      return this.$store.state.CompilationsStore.isEditMode;
    }
  },
  mounted() {
    this.paraId = this.$_getParaId();
    this.selector = 'control-' + this.paraId;
  },
  methods: {
    moveUp() {
      const paraId = this.paraId;
      this.emit(CompilationEventsEnum.MOVE_UP, { paraId });
    },
    moveDown() {
      const paraId = this.paraId;
      this.emit(CompilationEventsEnum.MOVE_DOWN, { paraId });
    },
    openExtendedMenu() {
      const paraId = this.paraId;
      const direction = this.$_getDirection();
      const selector = this.selector;
      this.emit(CompilationEventsEnum.OPEN_MENU, {
        selector,
        paraId,
        direction
      });
    },
    emit(type, data) {
      this.$emit('editControls', {
        type,
        data
      });
    },
    $_getParaId() {
      const para = this.$_getPara();
      if (!para) {
        return '';
      }
      return para.getAttribute('id');
    },
    $_getDirection() {
      const para = this.$_getPara();
      const defaultDir = 'ltr';
      const closestDirElement = para.closest('[dir]');
      if (!closestDirElement) {
        return defaultDir;
      }
      return closestDirElement.getAttribute('dir');
    },
    $_getPara() {
      return this.$el.parentElement.querySelector('[data-before]');
    }
  }
};
</script>

<style lang="less">
@import '~@/assets/styles/base.less';

.compilation-controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  .control-block {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0 20px;
    padding-left: 1px;
    padding-top: 1px;

    @media @narrow {
      margin: 0 10px;
    }

    > div {
      pointer-events: none;

      &:first-of-type {
        @narrow: ~'(max-width: 768px)';
        position: relative;

        @media @narrow {
          margin-top: -15px;
        }

        button {
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            svg {
              opacity: 0.5;
            }
          }

          &:first-of-type {
            transform: rotate(180deg);
          }
        }

        svg {
          padding-bottom: 5px;
          transition: opacity 0.15s ease;
        }
      }

      &:last-of-type {
        @narrow: ~'(max-width: 768px)';
        margin-top: 15px;

        @media @narrow {
          margin-top: 0;
        }

        button {
          svg {
            transition: opacity 0.15s ease;
          }

          &:hover {
            svg {
              opacity: 0.5;
            }
          }
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .hidden-controls {
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    pointer-events: all;

    svg {
      .night-theme-template & {
        fill: #fff;
      }
    }
  }

  svg {
    fill: var(--black-color);
  }
}
</style>
