<template>
  <img
    v-if="!lazyLoad"
    :src="bookCoverPath"
    :alt="alternativeText"
    class="book-cover-container"
    @error="handleError"
  />
  <v-img
    v-else
    :lazy-src="bookCoverPath"
    :src="bookCoverPath"
    :alt="alternativeText"
    :eager="lazyLoad"
    class="book-cover-container"
    @error="handleError"
  ></v-img>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('BookCover.vue');
import { sizes as thumbSize } from '@shared/enums/ThumbSizesEnum';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import images from '@/assets/images';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';
import AssetTypeEnum from '@/enums/AssetTypeEnum';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';

export default {
  name: 'BookCover',
  props: {
    bookId: {
      type: String,
      default: ''
    },
    coverSize: {
      type: String,
      default: thumbSize.MEDIUM
    },
    publication: {
      type: Object,
      default: () => ({})
    },
    lazyLoad: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      bookCoverPath: null
    };
  },
  async fetch() {
    try {
      this.bookCoverPath = await this.$_getBookCoverPath(this.coverSize);
    } catch (error) {
      if (process.client || !this.isBlogApp) {
        logger.error(
          `Get error on load cover path bookId:${this.bookId} error: ${error}`
        );
      }
      this.handleError();
    }
  },
  computed: {
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    },
    alternativeBookCoverPath() {
      return this.publicationDetails?.type === PublicationsTypes.COLLECTION &&
        !this.publicationDetails?.cover
        ? images.collectionCover73
        : images.emptyCover;
    },
    publicationDetails() {
      return Object.keys(this.publication).length
        ? this.publication
        : this.$store.getters['LibraryStore/getPublicationById'](this.bookId);
    },
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    alternativeText() {
      return `${this.publicationDetails?.name}, ${this.publicationDetails?.author}`;
    }
  },
  watch: {
    publicationDetails: {
      deep: true,
      handler(val, oldVal) {
        this.$_setBookCoverPath(
          null,
          val?.assetsSource !== oldVal?.assetsSource
        );
      }
    },
    isOnline() {
      this.$_setBookCoverPath();
    },
    isNoResult(newValue) {
      this.$store.commit('ActivityStore/setIsNoResult', newValue);
    }
  },
  methods: {
    handleError() {
      const isErrorLoading = true;
      this.$_setBookCoverPath(isErrorLoading);
    },
    async $_setBookCoverPath(isErrorLoading, preload = false) {
      if (isErrorLoading) {
        this.bookCoverPath = this.alternativeBookCoverPath;
        return;
      }
      const bookCoverPath = await this.$_getBookCoverPath(this.coverSize);
      if (!preload) {
        this.bookCoverPath = bookCoverPath;
        return;
      }
      const image = new Image();
      image.src = bookCoverPath;
      image.onload = () => {
        this.bookCoverPath = bookCoverPath;
      };
      image.onerror = () => {
        this.bookCoverPath = this.alternativeBookCoverPath;
      };
    },
    async $_getBookCoverPath(size) {
      let assetSource = this.publicationDetails?.assetsSource;
      if (assetSource === AssetResourcesEnum.FS) {
        const isAssetDownloaded = await AssetsManager.isAssetDownloaded(
          this.bookId,
          AssetTypeEnum.COVER
        );
        assetSource = isAssetDownloaded
          ? AssetResourcesEnum.FS
          : AssetResourcesEnum.REMOTE;
      }
      return (
        (await AssetsManager.buildBookCoverPath(
          this.bookId,
          this.publicationDetails?.cover,
          size,
          assetSource
        )) || this.alternativeBookCoverPath
      );
    }
  }
};
</script>

<style lang="less" src="./BookCover.less"></style>
