<template>
  <div class="widget-wrapper">
    <slot name="custom-content">
      <div class="widget-content">
        <div class="content-pic">
          <img
            v-if="contentImage"
            height="144"
            width="144"
            :src="contentImage"
            :alt="''"
          />
        </div>
        <div
          v-if="contentTitle"
          class="content-title"
          v-html="contentTitle"
        ></div>
        <div
          v-if="contentMessage"
          class="content-text"
          v-html="contentMessage"
        ></div>
      </div>
    </slot>
    <slot name="custom-footer">
      <div class="widget-footer">
        <BaseButton
          v-if="secondaryFooterHandler"
          class="secondary-action"
          :class="secondaryActionClass"
          :color="isNightTheme ? '#f8fafc' : secondaryFooterHandlerColor"
          :text="secondaryFooterHandlerTypeText"
          :outlined="secondaryFooterHandlerTypeOutlined"
          large
          elevation="0"
          @click="secondaryFooterHandler"
        >
          {{ secondaryLabel }}
        </BaseButton>
        <BaseButton
          v-if="primaryFooterHandler"
          :color="primaryColor"
          large
          :text="primaryFooterHandlerText"
          elevation="0"
          :block="!secondaryFooterHandler"
          :disabled="primaryFooterHandlerDisabled"
          @click="primaryFooterHandler"
        >
          {{ primaryLabel }}
        </BaseButton>
      </div>
    </slot>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
export default {
  name: 'SignWidgetLayout',
  components: { BaseButton },
  props: {
    contentImage: {
      type: String,
      required: false,
      default: ''
    },
    contentTitle: {
      type: String,
      required: false,
      default: ''
    },
    contentMessage: {
      type: String,
      required: false,
      default: ''
    },
    secondaryFooterHandler: {
      type: Function,
      required: false,
      default: null
    },
    primaryFooterHandler: {
      type: Function,
      required: false,
      default: null
    },
    secondaryLabel: {
      type: String,
      required: false
    },
    primaryLabel: {
      type: String,
      required: false
    },
    primaryColor: {
      type: String,
      require: false,
      default: 'primary'
    },
    primaryFooterHandlerText: {
      type: Boolean,
      required: false,
      default: false
    },
    primaryFooterHandlerDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    secondaryActionClass: {
      type: String,
      required: false,
      default: ''
    },
    secondaryFooterHandlerColor: {
      type: String,
      required: false,
      default: 'black'
    },
    secondaryFooterHandlerTypeText: {
      type: Boolean,
      required: false,
      default: false
    },
    secondaryFooterHandlerTypeOutlined: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style lang="less">
.widget-wrapper {
  .widget-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 345px;

    .content-pic {
      display: flex;
      padding: 16px 0;
      justify-content: center;

      &:empty {
        display: none;
      }
    }

    > div {
      word-break: break-word;
      text-align: center;
    }

    .content-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 8px;
      white-space: pre-wrap;
    }
    .content-text {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #64748b;
      white-space: pre-wrap;
    }
  }

  .widget-footer {
    .v-btn--text {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .popup-content .popup-content-block > div {
    width: 100%;
  }
}
</style>
