<template>
  <div
    v-if="isEnabledPreview"
    v-show="showPreview"
    ref="bookPreview"
    class="book-preview-block present-publication"
    :style="wrappingReadSettingsStyles"
  >
    <BookScroll @BookScrollEvent="bookScrollEventHandler"> </BookScroll>
    <FloatingPlayButton
      class="floating-button-wrapper"
      :class="BUTTON_CLASS"
      :disabled="isFABDisabled"
      @floatingPlayButtonEvent="floatingPlayButtonEventHandler"
    />
    <AudioPlayer v-if="isAudioBook" />
    <ReadingArea v-if="isContentReady" />
  </div>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('BookPreview.vue');

import BookScrollEnum from '@/enums/BookScrollEnum';

import { mapGetters } from 'vuex';
import BookScroll from '@/components/views/BookScroll/BookScroll';
import AudioPlayer from '@/components/views/Audio/AudioPlayer';
import ReadingArea from '@/components/views/ReadingArea/ReadingArea.vue';
import FloatingPlayButton from '@/components/views/FloatingPlayButton/FloatingPlayButton.vue';

import FloatingPlayButtonEventEnum from '@/enums/FloatingPlayButtonEventEnum';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PublicationScrollLogicService from '@/services/PublicationLogic/PublicationScrollLogicService';
import PublicationLogicService from '@/services/PublicationLogic/PublicationLogicService';

// import PublicationsTypesEnum from '@/enums/PublicationsTypesEnum';

export default {
  name: 'BookPreview',
  components: {
    BookScroll,
    AudioPlayer,
    ReadingArea,
    FloatingPlayButton
  },
  data() {
    const BUTTON_CLASS = AppConstantsUtil.PLAY_BUTTON_CLASS;
    const shortTapHandler = PublicationLogicService.shortTapHandler.bind(
      this,
      this.$store
    );
    return {
      shortTapHandler,
      BUTTON_CLASS,
      previewLoaded: false
    };
  },
  computed: {
    ...mapGetters('ContextStore', [
      'isOnline',
      'getPreviewConfig',
      'getClientReaderUrl',
      'isDevice',
      'isElectron'
    ]),
    isFABDisabled() {
      return this.$store.getters['PublicationStore/getIsFABDisabled'];
    },
    isContentReady() {
      return this.$store.getters['PublicationStore/isContentReady'];
    },

    showPreview() {
      return this.isOnline;
    },
    isEnabledPreview() {
      return (
        this.getPreviewConfig?.enabled &&
        this.publicationId &&
        (this.isOnline || this.previewLoaded)
      );
    },
    bookLink() {
      if (process.server) {
        return '';
      }
      const clientReaderUrl = this.getClientReaderUrl;
      const { slug, previewKey } = this.getPreviewConfig;
      const targetOrigin =
        this.isDevice || this.isElectron ? '*' : clientReaderUrl;
      return new URL(
        `${slug}/?previewKey=${previewKey}&targetOrigin=${targetOrigin}`,
        clientReaderUrl
      );
    },
    publicationId() {
      return this.$store.getters['OpenParameterStore/getPublicationId'];
    },
    isAudioBook() {
      return this.$store.getters['PublicationStore/isAudioPublication'](
        this.publicationId
      );
    },
    currentScrubberLocator() {
      return this.$store.getters['PlaybackStore/getCurrentLocator'];
    },
    isAudioPlaying() {
      return this.$store.getters['PlaybackStore/isAudioPlaying'];
    },
    isDragging() {
      return this.$store.getters['PlaybackStore/isDragging']();
    },
    wrappingReadSettingsStyles() {
      return this.$store.getters[
        'ReadingSettingsStore/getWrappingReadingSettingsStyles'
      ];
    }
  },
  watch: {
    async isEnabledPreview(isEnabled) {
      if (isEnabled) {
        try {
          await this.$store.dispatch(
            'PublicationStore/readMeta',
            this.publicationId
          );
        } catch (error) {
          logger.warn(
            `Get error in load meta for book preview error: ${error}`
          );
        }
      }
    },
    isAudioPlaying(isPlaying) {
      const isAudioStartPlaying = this.$store.getters[
        'PublicationStore/getIsAudioStartPlaying'
      ];
      this.$store.commit(
        'PublicationStore/setIsAudioStartPlaying',
        !isAudioStartPlaying && isPlaying
      );
      if (isPlaying) {
        this.$store.commit('PublicationStore/setIsFABDisabled', false);
      }
    },
    isDragging(val) {
      if (val) {
        this.$store.commit(
          'PublicationStore/setSkipCurrentLocatorChange',
          true
        );
      }
    },
    async currentScrubberLocator(newScrubberLocator, oldScrubberLocator) {
      await PublicationScrollLogicService.applyScrollOnScrubberChange(
        this.$store,
        this.$router,
        newScrubberLocator,
        oldScrubberLocator
      );
    }
  },
  mounted() {
    this.addShortTapHandler();
  },
  beforeDestroy() {
    this.removeShortTapHandler();
  },
  methods: {
    floatingPlayButtonEventHandler(payload) {
      const { type } = payload;
      switch (type) {
        case FloatingPlayButtonEventEnum.PLAY_AUDIO:
          PublicationLogicService.startFromThirdOfScreenOrContinuePlay(
            this.$store,
            this.$router
          );
          break;
      }
    },

    addShortTapHandler() {
      const previeRef = this.$_getPreviewRef();
      if (previeRef) {
        previeRef.addEventListener('touchend', this.shortTapHandler);
      }
    },

    $_getPreviewRef() {
      return this.$refs.bookPreview;
    },

    removeShortTapHandler() {
      const previeRef = this.$_getPreviewRef();
      if (previeRef) {
        previeRef.removeEventListener('touchend', this.shortTapHandler);
      }
    },
    bookScrollEventHandler(payload) {
      const { type, data } = payload;
      switch (type) {
        case BookScrollEnum.STABLE: {
          this.previewLoaded = true;
          this.$store.commit('PublicationStore/setIsFABDisabled', false);
          this.$_playParagraphOnOpen();
          break;
        }
        case BookScrollEnum.ON_READING_AREA_CHANGE: {
          if (data.isScrolling) {
            PublicationScrollLogicService.updateIsScrolling(this.$store, true);
          }
          if (data.isTooFar) {
            PublicationScrollLogicService.updateIsScrolling(this.$store, false);
          }
          break;
        }
        case BookScrollEnum.STOP: {
          PublicationScrollLogicService.updateIsScrolling(this.$store, false);
          break;
        }
      }
    },
    $_playParagraphOnOpen() {
      this.$store.dispatch('PlaybackStore/playPostponedParagraph');
    }
  }
};
</script>

<style lang="less" src="../PresentPublication/PresentPublication.less"></style>
<style lang="less" src="./BookPreview.less"></style>
