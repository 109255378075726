import MaterialPositions from '@/enums/MaterialPositions';

class ParagraphMaterials {
  constructor() {
    this.beforePara = [];
    this.afterPara = [];
  }

  addMaterialsBeforePara(materials) {
    this.beforePara = [...this.beforePara, ...materials];
  }

  addMaterialsAfterPara(materials) {
    this.afterPara = [...this.afterPara, ...materials];
  }

  deleteMaterialAfterParaByIndex(index) {
    this._deleteMaterialByIndexInTarget(index, this.afterPara);
  }

  deleteMaterialBeforeParaByIndex(index) {
    this._deleteMaterialByIndexInTarget(index, this.beforePara);
  }

  insertMaterialAfterParaByIndex(material, index) {
    this._insertMaterialByIndexInTarge(material, index, this.afterPara);
  }

  insertMaterialBeforeParaByIndex(material, index) {
    this._insertMaterialByIndexInTarge(material, index, this.beforePara);
  }

  getMaterialAfterParaIndex(material) {
    return this.afterPara.findIndex(mat => (mat.id || mat._id) === material.id);
  }

  _deleteMaterialByIndexInTarget(index, target) {
    target.splice(index, 1);
  }

  _insertMaterialByIndexInTarge(material, index, target) {
    target.splice(index, 0, material);
  }
}

function getParagraphMaterials(materials) {
  let paragraphMaterials = new ParagraphMaterials();
  paragraphMaterials.addMaterialsBeforePara(
    materials.paragraphNotes.filter(
      note => note.position === MaterialPositions.BEFORE_PARAGRAPH
    )
  );
  paragraphMaterials.addMaterialsAfterPara(
    materials.paragraphNotes.filter(
      note => note.position === MaterialPositions.AFTER_PARAGRAPH
    )
  );
  paragraphMaterials.addMaterialsAfterPara(materials.essayTask);
  paragraphMaterials.addMaterialsAfterPara(materials.quizes);
  paragraphMaterials.addMaterialsAfterPara(materials.flashcards);
  return paragraphMaterials;
}

export default { getParagraphMaterials };
