<template>
  <div ref="highlight" class="highlighter" :class="highlight?.highlightClass" />
</template>
<script>
import throttle from 'lodash/throttle';
import LoggerFactory from '@/services/utils/LoggerFactory';
import HighlightOptionsEnum from '@/enums/HighlightOptionsEnum';
import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';
const logger = LoggerFactory.getLogger('Highlight.vue');

const highlightBgMapDarken = {
  [ThemeClassNamesEnum.LIGHT]: {
    background: 'rgb(255 255 255 / 55%)'
  },
  [ThemeClassNamesEnum.SEPIA]: {
    background: 'rgb(245 237 223 / 50%)'
  },
  [ThemeClassNamesEnum.NIGHT]: {
    background: 'rgb(32 32 32 / 50%)'
  }
};

const highlightClassMap = {
  [HighlightOptionsEnum.DARKEN]: {
    highlightClass: 'darken',
    highlighterType: '_drawDarken',
    highlightBgMap: highlightBgMapDarken
  },
  [HighlightOptionsEnum.HIGHLIGHT]: {
    highlightClass: 'fill',
    highlighterType: '_drawFill',
    highlightBgMap: {}
  },
  [HighlightOptionsEnum.UNDERLINE]: {
    highlightClass: 'underline',
    highlighterType: '_drawUnderline',
    highlightBgMap: {}
  }
};

const fixingHighlightExceptions = [
  { name: 'SINGLE_QUOTATION_MARK', code: 8217 },
  { name: 'DOUBLE_QUOTES', code: 8221 },
  { name: 'APOSTROPHE', code: 39 }
];

export default {
  props: {
    direction: {
      type: String,
      required: true,
      default: 'rtl'
    },
    locator: {
      type: String,
      required: true
    },
    wordElement: {
      type: [HTMLElement],
      required: true
    }
  },
  data() {
    return {
      darkenGradientPercentage: 0,
      darkenGradientDirection: 'ltr',
      fixingHighlightSpace: 5
    };
  },
  computed: {
    highlightMode() {
      return this.$store.getters['ReadingSettingsStore/getHighlightMode'];
    },
    highlight() {
      return highlightClassMap[this.highlightMode];
    },
    themeName() {
      return this.$store.getters['ReadingSettingsStore/getThemeName'];
    },
    themeBg() {
      return this.highlight?.highlightBgMap[this.themeName]?.background;
    },
    resizeHandler() {
      return throttle(() => this.updateHighlightDimensions(), 100);
    },
    narrow() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    }
  },
  watch: {
    locator() {
      return this.updateHighlightDimensions();
    },
    highlightMode(mode) {
      this.$_cleanupStyles(mode);
      return this.updateHighlightDimensions();
    },
    themeBg() {
      this._setDarkenBg();
    }
  },
  mounted() {
    this.updateHighlightDimensions();
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    updateHighlightDimensions() {
      const defaultHighlighter = '_drawFill_ltr';
      if (!this.wordElement || !this.highlight) {
        return;
      }
      const wordRect = this.wordElement.getBoundingClientRect();
      const containerRect = this.$el.parentElement.getBoundingClientRect();
      const direction = this.direction || 'ltr';
      const highlighterType = this.highlight.highlighterType;
      let highlighter = `${highlighterType}_${direction}`;
      if (!this.hasOwnProperty(highlighter)) {
        logger.warn(
          `try apply unsupported highlighter: ${highlighter} set defaultHighlighter ${defaultHighlighter}`
        );
        highlighter = defaultHighlighter;
      }
      this[highlighter](containerRect, wordRect);
    },
    isWordEndsWithSemicolon() {
      const textContent = this.wordElement?.textContent;
      if (!textContent) {
        return false;
      }

      const lastCharCode = textContent.charCodeAt(textContent.length - 1);
      return fixingHighlightExceptions.some(data => data.code === lastCharCode);
    },
    $_cleanupStyles(highlightMode) {
      const resetValue =
        highlightMode === HighlightOptionsEnum.DARKEN ? null : '0';

      this.$refs.highlight.style.background = null;
      this.$refs.highlight.style.width = resetValue;
      this.$refs.highlight.style.left = resetValue;
      this.$refs.highlight.style.right = resetValue;
      this.$refs.highlight.style['margin-inline-start'] = resetValue;
      this.$refs.highlight.style['margin-inline-end'] = resetValue;
    },

    _drawDarken_ltr(containerRect, wordRect) {
      let margin = wordRect.x + wordRect.width - containerRect.x;
      if (this.isWordEndsWithSemicolon()) {
        margin += this.fixingHighlightSpace;
      }
      const direction = 'ltr';
      this._drawDarken(containerRect, margin, direction);
    },

    _drawDarken_rtl(containerRect, wordRect) {
      const margin = containerRect.right - wordRect.x;
      const direction = 'rtl';
      this._drawDarken(containerRect, margin, direction);
    },

    _drawDarken(containerRect, margin, direction) {
      this.darkenGradientPercentage = (margin * 100) / containerRect.width;
      this.darkenGradientDirection = direction === 'ltr' ? 'right' : 'left';
      this._setDarkenBg();
    },

    _setDarkenBg() {
      this.$refs.highlight.style.background = `linear-gradient(
        to ${this.darkenGradientDirection},
        transparent 0,
        transparent ${this.darkenGradientPercentage}%,
        ${this.themeBg} ${this.darkenGradientPercentage}%,
        ${this.themeBg} 100%
      )`;
    },

    _getRightContolMargin() {
      return this.narrow ? 0 : 15;
    },

    _drawUnderline_ltr(containerRect, wordRect) {
      let underlineWidth = wordRect.width;
      if (this.isWordEndsWithSemicolon()) {
        underlineWidth += this.fixingHighlightSpace;
      }
      this.$refs.highlight.style.left = `${wordRect.x - containerRect.x}px`;
      this.$refs.highlight.style.width = `${underlineWidth}px`;
    },

    _drawUnderline_rtl(containerRect, wordRect) {
      this.$refs.highlight.style.right = `${containerRect.right -
        wordRect.x -
        wordRect.width}px`;
      this.$refs.highlight.style.width = `${wordRect.width}px`;
    },

    _drawFill_ltr(containerRect, wordRect) {
      let width = wordRect.x + wordRect.width - containerRect.x;
      if (this.isWordEndsWithSemicolon()) {
        width += this.fixingHighlightSpace;
      }
      this.$refs.highlight.style.left = 0;
      this.$refs.highlight.style.width = `${width}px`;
    },

    _drawFill_rtl(containerRect, wordRect) {
      const width = containerRect.right - wordRect.x;
      this.$refs.highlight.style.right = 0;
      this.$refs.highlight.style.width = `${width}px`;
    }
  }
};
</script>

<style lang="less" src="./Highlight.less"></style>
