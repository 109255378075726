<template>
  <div>
    <div
      :class="{ '-user-logged-in': isLoggedIn }"
      dir="auto"
      class="about-content px-4 px-md-6 px-lg-6 py-3"
    >
      <section class="about-section -section-one flex-wrap">
        <div class="section-side flex-column">
          <div
            class="card text-color-white card-bg-primary pa-6 pa-lg-8 flex-grow-1"
          >
            <div class="card-top">
              <div class="card-title">
                Boost Comprehension and Vocabulary with Immersive Reading
              </div>
              <div class="card-text">
                Merging human narration with text for an engaging, and
                interactive literary adventure
              </div>
            </div>
            <div class="card-bottom -for-logged-in">
              <TrialButton />
            </div>
          </div>
          <!--card-->
          <div
            class="card card-small card-row card-bg-gray -for-logged-in pa-6 pa-lg-8"
          >
            <div class="card-top">
              <div class="card-title">
                Seamless integration of text and human narration
              </div>
              <div class="card-text">
                Engages both eye and ear for enhanced comprehension, enabling
                natural vocabulary and grammar absorption
              </div>
            </div>
          </div>
          <!--card-->
        </div>
        <!--section-side-->
        <div class="section-side sign-widget-wrapper -for-guest">
          <div ref="signWidget" class="sign-widget-anchor"></div>
          <div class="card card-bordered pa-6 pa-lg-8 flex-grow-1">
            <div class="card-top">
              <div class="card-title">
                Sign Up for Free to Unlock Reading Potential
              </div>
            </div>
            <div class="card-bottom">
              <SignWidget :init-state="signWidgetInitState" />
            </div>
          </div>
        </div>
        <!--section-side-->
        <div class="section-side phone-block">
          <div class="card card-bg-sand align-center justify-end flex-grow-1">
            <div class="card-pic-block">
              <div class="dummy-book-small">
                <v-img :lazy-src="dummyBook" :src="dummyBook" alt="dummyBook" />
              </div>
              <div class="phone-pic">
                <v-img :lazy-src="phonePic" :src="phonePic" alt="phonePic" />
              </div>
            </div>
          </div>
        </div>
        <!--section-side-->
      </section>
      <!--section-->

      <section v-if="!isPurchaseEnabled" class="about-section -section-ticker">
        <div
          class="card card-small card-row text-color-white card-bg-secondary-accent py-5"
        >
          <div class="ticker">
            Enjoy your vacation with WholeReader. Read on any devices for free
            <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
            Enjoy your vacation with WholeReader. Read on any devices for free
            <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
            Enjoy your vacation with WholeReader. Read on any devices for free
            <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
            Enjoy your vacation with WholeReader. Read on any devices for free
            <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
          </div>
        </div>
        <!--card-->
      </section>
      <!--section-->
      <section class="about-section -section-two flex-wrap">
        <div class="section-side flex-grow-3">
          <div class="section-side flex-grow-1">
            <div class="card card-bordered card-small pa-6 pa-lg-8 flex-grow-1">
              <div class="card-top">
                <div class="card-title">
                  <div>Immersive Vocabulary Learning</div>
                  <div class="feature-pic-block">
                    <v-img
                      :lazy-src="featuresPic6"
                      :src="featuresPic6"
                      alt="featuresPic6"
                    />
                  </div>
                </div>
              </div>
              <div class="card-bottom">
                Concerned about your child's reading skills? Engage both eye and
                ear simultaneously for a captivating learning experience that
                naturally boosts vocabulary absorption.
              </div>
            </div>
            <!--card-->
            <div class="card card-bordered card-small pa-6 pa-lg-8 flex-grow-1">
              <div class="card-top">
                <div class="card-title">
                  <div>Boosted Comprehension</div>
                  <div class="feature-pic-block">
                    <v-img
                      :lazy-src="featuresPic7"
                      :src="featuresPic7"
                      alt="featuresPic7"
                    />
                  </div>
                </div>
              </div>
              <div class="card-bottom">
                Is your child struggling with reading comprehension? Reading is
                fun and learning happens when comprehension is high. And nothing
                boosts comprehension as much as integrated human narration,
                creating an engaging read-along experience.
              </div>
            </div>
            <!--card-->
          </div>
          <!--section-side-->
          <div class="section-side flex-grow-1">
            <div class="card card-bordered card-small pa-6 pa-lg-8 flex-grow-1">
              <div class="card-top">
                <div class="card-title">
                  <div>Natural Pronunciation</div>
                  <div class="feature-pic-block">
                    <v-img
                      :lazy-src="featuresPic9"
                      :src="featuresPic9"
                      alt="featuresPic9"
                    />
                  </div>
                </div>
              </div>
              <div class="card-bottom">
                English pronunciation is hard! Boost spoken English confidence
                through constant exposure to accurate pronunciation in
                captivating real literature.
              </div>
            </div>
            <!--card-->
            <div class="card card-bordered card-small pa-6 pa-lg-8 flex-grow-1">
              <div class="card-top">
                <div class="card-title">
                  <div>Customized Reading Paths</div>
                  <div class="feature-pic-block">
                    <v-img
                      :lazy-src="featuresPic10"
                      :src="featuresPic10"
                      alt="featuresPic10"
                    />
                  </div>
                </div>
              </div>
              <div class="card-bottom">
                Don't torture the kids with generic learning materials! Give
                them a range of choice from books which are targeted to your
                child's specific vocabulary level. They will naturally climb the
                ladder of difficulty from children's books to Ivy-League reading
                requirements.
              </div>
            </div>
            <!--card-->
          </div>
          <!--section-side-->
        </div>
        <!--section-side-->
        <div v-if="!isDevice" class="section-side flex-grow-1">
          <div class="card card-bordered pa-6 pa-lg-8 flex-grow-1 d-none">
            <div class="card-top">
              <div class="card-title2">
                Unlock Reading Potential
              </div>
              <div class="card-text2">
                Experience the remarkable benefits of WholeReader's innovative
                approach to reading
              </div>
            </div>
            <div class="card-bottom">
              <TrialButton color="primary" />
            </div>
          </div>
          <!--card-->
          <DonationBlock
            class="card card-row donation-block"
            :donation-title="donationBlockTitle"
            :donation-description="donationBlockDescription"
            :control-label="donationControlLabel"
          />
        </div>
        <!--section-side-->
      </section>
      <!--section-->
      <section class="about-section -section-five">
        <div class="card card-bg-sand pa-6 pa-md-10 flex-grow-1">
          <div class="text-block1">immersive reading</div>
          <h2 class="title-block">Combining the Ear and the Eye</h2>
          <p class="text-block2">
            By tying each word to narrated audio, we bring both the ear and the
            eye into the reading process. Thereby boosting reading comprehension
            and dramatically accelerating vocabulary absorption.
          </p>
          <BaseButton
            v-if="!isOffline"
            class="align-self-center"
            x-large
            elevation="0"
            @click="playBookFragment"
            ><BaseSpriteIcon icon-name="ico-book-info-read-solid" />Try
            Read-along Fragment</BaseButton
          >
          <p v-if="!isOffline" class="section-bottom-text">
            Make sure that the sound is on
          </p>

          <BookPreview></BookPreview>

          <TrialButton
            class="-for-guest mt-12"
            @controlEvent="trialButtonHandler"
          />
        </div>
      </section>
      <!--section-->
      <section class="about-section -section-three flex-wrap">
        <div
          class="card text-color-white card-row card-bg-primary pa-6 pa-lg-8"
        >
          <div class="section-side flex-column flex-grow-1">
            <div class="card-top">
              <div class="card-title3">
                This Immersive Reading technology dramatically boost you reading
                and listening comprehension skills. After 2 weeks you will see
                dramatic changes
              </div>
            </div>
            <div class="card-bottom">
              <TrialButton @controlEvent="trialButtonHandler" />
            </div>
          </div>
          <div class="section-side flex-grow-1">
            <div class="card-list-title mb-6">
              The WholeReader Process is simple:
            </div>
            <div class="card-list">
              <div
                v-for="(item, index) in stepsList"
                :key="index"
                class="card-list-item"
              >
                <div class="item-num">{{ index + 1 }}</div>
                <div class="item-text">{{ item }}</div>
              </div>
            </div>
          </div>
          <div class="narrow-block">
            <TrialButton @controlEvent="trialButtonHandler" />
          </div>
        </div>
      </section>
      <!--section-->
      <section class="about-section -section-four flex-wrap">
        <div class="section-side flex-grow-1">
          <div class="card flex-grow-1">
            <div class="video-block-wrapper">
              <lite-youtube
                id="lite-youtube-top"
                :videoid="YTIdTop"
                videotitle="YouTube video player"
              >
              </lite-youtube>
              <div
                v-show="showVideoPlaceholder"
                class="video-placeholder"
                @click="removeVideoPlaceholder"
              >
                <v-img
                  :lazy-src="videoPlaceholder"
                  :src="videoPlaceholder"
                  alt="videoPlaceholder"
                  class="video-placeholder-img"
                />
                <div class="ico-button-play">
                  <v-img
                    :lazy-src="icoButtonPlay"
                    :src="icoButtonPlay"
                    alt="icoButtonPlay"
                  />
                </div>
              </div>
              <!-- videoPlaceholder -->
            </div>
          </div>
        </div>
        <!--section-side-->
        <div class="section-side flex-grow-1">
          <div
            class="card card-bordered card-content-centered card-small pa-6 pa-lg-8 flex-grow-1"
          >
            <div class="card-top">
              <div class="card-text -big">
                In 2018 Microsoft ran an experiment in immersive reading. Over
                just three months, students spending 30 minutes per week in
                immersive reading shot ahead of their peers by approximately a
                full academic year.
              </div>
            </div>
            <div class="card-bottom -small">
              Microsoft Report
            </div>
          </div>
          <!--card-->
          <div
            class="card card-bordered card-content-centered card-small pa-6 pa-lg-8 flex-grow-1"
          >
            <div class="card-top">
              <div class="card-text -big">
                The average child at the 90th percentile reads almost two
                million words per year outside of school, more than 200 times
                more words than the child at the 10th percentile, who reads just
                8,000 words outside of school during a year.
              </div>
            </div>
            <div class="card-bottom -small">
              Cunningham and Stanovitch, “What Reading Does for the Mind”
            </div>
          </div>
          <!--card-->
        </div>
        <!--section-side-->
      </section>
      <!--section-->
      <section class="about-section  -section-seven flex-wrap">
        <div
          class="card text-color-white card-bg-primary card-content-centered card-small pa-10"
        >
          <div class="card-title">
            Available for all platforms
          </div>
          <div class="platforms-list">
            <div class="platform-item">
              <v-img :lazy-src="icoGlobe" :src="icoGlobe" alt="icoGlobe" />
              <div>Web</div>
            </div>
            <div class="platform-item">
              <v-img
                :lazy-src="icoPwa"
                :src="icoPwa"
                alt="icoPwa"
                class="ico-pwa"
              />
            </div>
            <div class="platform-item">
              <v-img
                :lazy-src="icoWindows"
                :src="icoWindows"
                alt="icoWindows"
              />
              <div>Windows</div>
            </div>
            <div class="platform-item">
              <v-img :lazy-src="icoApple" :src="icoApple" alt="icoApple" />
              <div>macOS</div>
            </div>
            <div class="platform-item">
              <v-img :lazy-src="icoLinux" :src="icoLinux" alt="icoLinux" />
              <div>Linux</div>
            </div>
            <div class="platform-item">
              <v-img
                :lazy-src="icoAndroid"
                :src="icoAndroid"
                alt="icoAndroid"
              />
              <div>Android</div>
            </div>
            <div class="platform-item">
              <v-img :lazy-src="icoApple" :src="icoApple" alt="icoApple" />
              <div>iOS</div>
            </div>
          </div>
          <div v-if="!isDevice" class="links-block">
            By downloading WholeReader, you accept our <br />
            <a :href="thermOfUseLink" rel="noopener noreferrer" target="_blank"
              >Terms Of Use</a
            >
            and
            <a
              :href="privacyPolicyLink"
              rel="noopener noreferrer"
              target="_blank"
              >Privacy Policy</a
            >
          </div>
        </div>
      </section>
      <!--section-->
      <section class="about-section -section-six section-carousel">
        <div class="section-side flex-grow-1">
          <div
            class="card text-color-white card-bg-secondary-accent pa-6 pa-lg-8 flex-grow-1"
          >
            <v-carousel v-model="carouseModel" continuous hide-delimiters>
              <template #prev="{ on, attrs }">
                <BaseButton
                  v-bind="attrs"
                  color="white"
                  light
                  fab
                  small
                  elevation="0"
                  v-on="on"
                  ><BaseSpriteIcon :icon-name="'ico-arrow-front-long'"
                /></BaseButton>
              </template>
              <template #next="{ on, attrs }">
                <BaseButton
                  v-bind="attrs"
                  color="white"
                  light
                  fab
                  small
                  elevation="0"
                  v-on="on"
                  ><BaseSpriteIcon :icon-name="'ico-arrow-front-long'"
                /></BaseButton>
              </template>
              <v-carousel-item>
                <div class="carousel-item">
                  <div class="carousel-quote-img">
                    <v-img :src="carouselQuote" alt="carousel-pic" />
                  </div>
                  <div class="carousel-text">
                    With WholeReader, you don't just read a book, you listen to
                    it too and it helps you improve your pronunciation. I am
                    learning many new words every day, which I would never have
                    been able to do while learning English at school.
                  </div>
                  <div class="carousel-sign">Mino <span>Madagascar</span></div>
                </div>
              </v-carousel-item>
              <v-carousel-item>
                <div class="carousel-item">
                  <div class="carousel-quote-img">
                    <v-img :src="carouselQuote" alt="carousel-pic" />
                  </div>
                  <div class="carousel-text">
                    I trained my Iraqi student, a mother with reasonable English
                    to help her 8 year old boy who had difficulty in English
                    class in school, to use WholeReader stories as an
                    extracurricular activity to improve his reading. After two
                    months of reading every day for one hour with his mother. He
                    was able to catch up with his classmates. The school teacher
                    was very surprised by this improvement.
                  </div>
                  <div class="carousel-sign">
                    Liliane Saberin <span>Clearwater FL</span>
                  </div>
                </div>
              </v-carousel-item>
              <v-carousel-item>
                <div class="carousel-item">
                  <div class="carousel-quote-img">
                    <v-img :src="carouselQuote" alt="carousel-pic" />
                  </div>
                  <div class="carousel-text">
                    When it comes to reading, many benefits come with it. But
                    one of the most important benefits is how reading can change
                    a person's inner life permanently. Reading has the power to
                    open up a person's mind to new and different ideas, which
                    can then lead to a person growing and developing as a
                    person.
                  </div>
                  <div class="carousel-sign">
                    Chad Jones <span>Parent</span>
                  </div>
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>
          <!--card-->
        </div>
        <!--section-side-->
        <div class="section-side flex-grow-1">
          <div
            class="card card-bordered card-content-centered card-small pa-6 pa-lg-8 flex-grow-1"
          >
            <h2 class="faq-title">Frequently asked questions</h2>
            <p class="faq-text">
              If you cannot find an answer here, please send a message using the
              <strong>Contact Us</strong> form or reach out to us at
              <a href="mailto:support@wholereader.com">
                support@wholereader.com</a
              >
            </p>
            <div class="panels-wrapper">
              <v-expansion-panels flat tile>
                <v-expansion-panel
                  v-for="(qa, qaIndex) in getFAQ"
                  :key="`qa-${qaIndex}`"
                  class="faq-item-block"
                >
                  <v-expansion-panel-header>
                    {{ qa.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ qa.answer }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div class="asdasd">
              <NuxtLink
                :to="faq"
                class="ir-btn ir-btn-small ir-btn-text ir-btn-plain"
              >
                see more on FAQ page
              </NuxtLink>
            </div>
          </div>
          <!--card-->
        </div>
        <!--section-side-->
      </section>
      <!--section-->
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import DonationBlock from '@/components/base/DonationBlock/DonationBlock.vue';
import AppFooter from '@/components/views/AppFooter/AppFooter.vue';
import SignWidget from '@/components/views/AboutView/ffa/SignWidget.vue';
import BookPreview from '@/components/views/BookPreview/BookPreview.vue';
import PublicationLogicService from '@/services/PublicationLogic/PublicationLogicService';
import AppStateEnum from '@/enums/AppStateEnum';
import TrialButton from './TrialButton.vue';

import LoginStatesEnum from '@/enums/LoginStatesEnum';

import phonePic from '@/components/views/AboutView/ffa/img/phone-pic.webp';
import dummyBook from '@/components/views/AboutView/ffa/img/dummy-book.webp';
import featuresPic6 from '@/components/views/AboutView/ffa/img/features-pic6.svg';
import featuresPic7 from '@/components/views/AboutView/ffa/img/features-pic7.svg';
import featuresPic9 from '@/components/views/AboutView/ffa/img/features-pic9.svg';
import featuresPic10 from '@/components/views/AboutView/ffa/img/features-pic10.svg';
import carouselQuote from '@/components/views/AboutView/ffa/img/ico-quote.svg';
import icoAndroid from '@/components/views/AboutView/ffa/img/ico-android.svg';
import icoApple from '@/components/views/AboutView/ffa/img/ico-apple.svg';
import icoGlobe from '@/components/views/AboutView/ffa/img/ico-globe.svg';
import icoLinux from '@/components/views/AboutView/ffa/img/ico-linux.svg';
import icoPwa from '@/components/views/AboutView/ffa/img/ico-pwa.svg';
import icoWindows from '@/components/views/AboutView/ffa/img/ico-windows.svg';
import icoButtonPlay from '@/components/views/AboutView/ffa/img/ico-button-play.svg';
import icoSun from '@/components/views/AboutView/ffa/img/ico-sun.svg';
import videoPlaceholder from '@/components/views/AboutView/ffa/img/video-placeholder.webp';

export default {
  name: 'AboutViewFfa',
  components: {
    BaseSpriteIcon,
    BaseButton,
    DonationBlock,
    AppFooter,
    SignWidget,
    BookPreview,
    TrialButton
  },
  data() {
    const thermOfUseLink = this.$store.getters['ContextStore/getTermOfUseLink'];
    const privacyPolicyLink = this.$store.getters[
      'ContextStore/getPrivacyPolicyLink'
    ];
    const donationBlockTitle = 'Support our project';
    const donationBlockDescription =
      'We are proud to be a non-profit organization that allows you to learn English for free. You can invest in the development of our project as a one-time donation or through monthly payments. Help us improve our product <br/><br/> IMMERSIVE OCEAN, INC';
    return {
      phonePic,
      dummyBook,
      featuresPic6,
      featuresPic7,
      featuresPic9,
      featuresPic10,
      carouselQuote,
      icoAndroid,
      icoApple,
      icoGlobe,
      icoLinux,
      icoPwa,
      icoWindows,
      icoButtonPlay,
      icoSun,
      videoPlaceholder,
      carouseModel: 0,
      YTIdFQY: 'CsiU61jmD-8',
      YTIdTop: 'vzmJ8yuMaHk',
      showVideoPlaceholder: true,
      stepsList: [
        'Sign up for free',
        'Assess reading level',
        'Select a suggested book',
        'Read along 20 minutes each day',
        'Periodically re-assess'
      ],
      thermOfUseLink,
      privacyPolicyLink,
      signWidgetInitState: LoginStatesEnum.REGISTRATION,
      donationBlockTitle,
      donationBlockDescription,
      donationControlLabel: this.$t('App.Button.label.Donate'),
      faq: { name: AppStateEnum.FREQUENTLY_ASKED_QUESTIONS }
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['isPurchaseEnabled', 'isDevice']),
    isOffline() {
      return !this.$store.getters['ContextStore/isOnline'];
    },
    isLoggedIn() {
      return this.$store.getters['UserStore/isLoggedIn'];
    },
    getFAQ() {
      const faqData = this.$t('FAQ.ffa', { returnObjects: true });
      const firstCategory = Object.keys(faqData)[0];
      const questions = faqData[firstCategory].slice(0, 7);
      return questions;
    }
  },
  methods: {
    isAudioPlaying() {
      return this.$store.getters['PlaybackStore/isAudioPlaying'];
    },
    async playBookFragment() {
      if (this.isAudioPlaying()) {
        this.$store.dispatch('PlaybackStore/pause');
      }
      await PublicationLogicService.immediatePlayAudioFromStartRange(
        this.$store,
        this.$router
      );
    },
    removeVideoPlaceholder() {
      this.showVideoPlaceholder = false;
      const youTubeVideo = document.getElementById('lite-youtube-top');
      youTubeVideo.style.setProperty('opacity', 1);
      youTubeVideo.click();
    },
    trialButtonHandler({ type }) {
      switch (type) {
        case 'scrollToAnchor':
          this.$refs.signWidget.scrollIntoView({ behavior: 'smooth' });
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="less" src="./AboutViewFfa.less"></style>
