class WidgetState {
  constructor(options = {}) {
    this.state = options.state || null;
    this.contentImage = options.contentImage || null;
    this.contentTitle = options.contentTitle || '';
    this.contentMessage = options.contentMessage || '';
    this.component = options.component || null;
    this.primaryLabel = options.primaryLabel || '';
    this.primaryFooterHandler = options.primaryFooterHandler || null;
    this.primaryFooterHandlerDisabled =
      options.primaryFooterHandlerDisabled || false;
    this.primaryFooterHandlerText = options.primaryFooterHandlerText || null;
    this.secondaryLabel = options.secondaryLabel || '';
    this.secondaryFooterHandler = options.secondaryFooterHandler || null;
    this.secondaryFooterHandlerDisabled =
      options.secondaryFooterHandlerDisabled || false;
    this.secondaryFooterHandlerText =
      options.secondaryFooterHandlerText || null;
  }
  setState(state) {
    this.state = state;
    return this;
  }
  setContentImage(contentImage) {
    this.contentImage = contentImage;
    return this;
  }
  setContentTitle(contentTitle) {
    this.contentTitle = contentTitle;
    return this;
  }
  setContentMessage(contentMessage) {
    this.contentMessage = contentMessage;
    return this;
  }
  setComponent(component) {
    this.component = component;
    return this;
  }
  setPrimaryLabel(primaryFooterLabel) {
    this.primaryLabel = primaryFooterLabel;
    return this;
  }
  setPrimaryFooterHandler(primaryFooterHandler) {
    this.primaryFooterHandler = primaryFooterHandler;
    return this;
  }
  setPrimaryFooterHandlerText(primaryFooterHandlerText) {
    this.primaryFooterHandlerText = primaryFooterHandlerText;
    return this;
  }
  setPrimaryFooterHandlerDisabled(primaryFooterHandlerDisabled) {
    this.primaryFooterHandlerDisabled = primaryFooterHandlerDisabled;
    return this;
  }
  setSecondaryLabel(secondaryFooterLabel) {
    this.secondaryLabel = secondaryFooterLabel;
    return this;
  }
  setSecondaryFooterHandler(secondaryFooterHandler) {
    this.secondaryFooterHandler = secondaryFooterHandler;
    return this;
  }
  setSecondaryFooterHandlerText(secondaryFooterHandlerText) {
    this.secondaryFooterHandlerText = secondaryFooterHandlerText;
    return this;
  }
  setSecondaryFooterHandlerDisabled(secondaryFooterHandlerDisabled) {
    this.secondaryFooterHandlerDisabled = secondaryFooterHandlerDisabled;
    return this;
  }
}

const createState = options => new WidgetState(options);

export default { createState };
