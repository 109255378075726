<template>
  <div v-if="showPublicationProgress" class="publication-progress">
    <div v-if="progressSummaryItem.completed" class="completed-block">
      <BaseSpriteIcon icon-name="ico-completed" />
    </div>
    <div v-if="showProgress" class="progress-block">
      <div class="progress-bg">
        <div
          class="progress-val"
          :style="{ width: progressSummaryItem.readPercent + '%' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'PublicationProgress',
  components: { BaseSpriteIcon },
  props: {
    publicationId: {
      type: String,
      required: true
    }
  },
  computed: {
    showPublicationProgress() {
      return this.$store.getters['ContextStore/showPublicationProgress'];
    },
    showProgress() {
      return (
        !this.progressSummaryItem.completed &&
        this.progressSummaryItem.readPercent >=
          AppConstantsUtil.START_PERCENT_BORDER
      );
    },
    progressSummaryItem() {
      return this.$store.getters['ProgressStore/getPublicationProgress'](
        this.publicationId
      );
    }
  },
  methods: {}
};
</script>

<style lang="less">
.publication-progress {
  display: flex;
  justify-content: flex-end;

  .completed-block {
    width: 28px;
    height: 28px;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 55%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 50%;
    margin: 9px 0;

    .recent-item & {
      margin: 0;
    }

    svg {
      fill: #68b415;
      width: 28px;
      height: 28px;
    }
  }

  .progress-block {
    position: relative;
    display: flex;
    flex-grow: 1;

    .progress-bg {
      flex-grow: 1;
      background: #ffffff;
      border-radius: 3px;
      overflow: hidden;
    }

    .progress-val {
      background: #fbc333;
      height: 6px;
      border-radius: 3px;
    }
  }
}
</style>
