<template>
  <div ref="arrow" class="popup-arrow" :style="styles" />
</template>

<script>
import TooltipAlignEnum from '@/enums/TooltipAlignEnum';
const ARROW_WIDTH = 35;
export default {
  name: 'PopupArrow',
  props: {
    formElement: {
      type: [HTMLElement, SVGSVGElement],
      required: true
    },
    toElement: {
      type: [HTMLElement, SVGSVGElement],
      required: true
    },
    align: {
      type: String,
      required: true
    },
    xOffset: {
      type: Number,
      required: false,
      default: 0
    },
    yOffset: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    styles() {
      const backgroundColor = window
        .getComputedStyle(this.formElement, null)
        .getPropertyValue('background-color');

      const style = this.alineArrow(this.align);
      style['--arrowBg'] = backgroundColor;
      return style;
    }
  },
  methods: {
    alineArrow(align) {
      const fromClientRect = this.formElement.getBoundingClientRect();
      const toClientRect = this.toElement.getBoundingClientRect();
      let arrowLeft = this.$_calcLeft(toClientRect);

      let height;
      let arrowTop;
      let style = {};
      const RETINA_GAP_OVERLAP = 1;
      switch (align) {
        case TooltipAlignEnum.BOTTOM:
          height =
            fromClientRect.y -
            (toClientRect.height + toClientRect.y) +
            RETINA_GAP_OVERLAP;
          arrowTop = this.yOffset + toClientRect.y + toClientRect.height;
          break;
        case TooltipAlignEnum.TOP:
          height =
            toClientRect.y -
            fromClientRect.y -
            fromClientRect.height +
            RETINA_GAP_OVERLAP;
          arrowTop = this.yOffset + toClientRect.y - height;
          style = {
            transform: 'rotate(180deg)'
          };
          break;
      }

      return Object.assign(style, {
        width: ARROW_WIDTH + 'px',
        height: height + 'px',
        left: arrowLeft + 'px',
        top: arrowTop + 'px'
      });
    },
    $_calcLeft(toClientRect) {
      return (
        toClientRect.x +
        this.xOffset +
        Math.round(toClientRect.width / 2) -
        Math.round(ARROW_WIDTH / 2)
      );
    },
    alignTop() {
      const fromClientRect = this.formElement.getBoundingClientRect();
      const toClientRect = this.toElement.getBoundingClientRect();

      let arrowLeft =
        toClientRect.x +
        Math.round(toClientRect.width / 2) -
        Math.round(ARROW_WIDTH / 2);

      const height = toClientRect.y - fromClientRect.y - fromClientRect.height;
      const arrowTop = toClientRect.y - height;

      return {
        width: ARROW_WIDTH + 'px',
        height: height + 'px',
        left: arrowLeft + 'px',
        top: arrowTop + 'px',
        transform: 'rotate(180deg)'
      };
    },
    alignBottom() {
      const fromClientRect = this.formElement.getBoundingClientRect();
      const toClientRect = this.toElement.getBoundingClientRect();

      let arrowLeft =
        toClientRect.x +
        Math.round(toClientRect.width / 2) -
        Math.round(ARROW_WIDTH / 2);

      const height = fromClientRect.y - (toClientRect.height + toClientRect.y);
      const arrowTop = toClientRect.y + toClientRect.height;

      return {
        width: ARROW_WIDTH + 'px',
        height: height + 'px',
        left: arrowLeft + 'px',
        top: arrowTop + 'px'
      };
    }
  }
};
</script>

<style lang="less" scoped>
@import 'PopupArrow.less';
</style>
