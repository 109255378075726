import Utils from '@/services/utils/Utils';

class Link {
  constructor(src, paraId) {
    this.id = Utils.uuid();
    this.src = src;
    this.paraId = paraId;
  }

  setSrc(val) {
    this.src = val;
  }

  setParaId(val) {
    this.paraId = val;
  }

  getParaId() {
    return this.paraId;
  }

  resetToEmpty() {
    this.src = '';
    this.paraId = '';
  }
}

function createEmptyLink() {
  const src = '';
  const paraId = '';
  return new Link(src, paraId);
}

function createLink(src, paraId) {
  return new Link(src, paraId);
}

export default {
  createEmptyLink,
  createLink
};
