<template>
  <BaseButton
    class="favourites-button"
    :class="{ active: isFavouritePublication }"
    x-small
    v-bind="$attrs"
    :aria-label="buttonLabel"
    @click="toggleFavourites()"
  >
    <BaseSpriteIcon :icon-name="iconName"></BaseSpriteIcon>
    <span v-if="isButtonLabelVisible">{{ buttonLabel }}</span>
  </BaseButton>
</template>

<script>
/**
 * Default control view - <button>
 * For icon only control view, should be set attr 'icon'
 */

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseButton from '@/components/base/BaseButton/BaseButton';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import LoginStatesEnum from '@/enums/LoginStatesEnum';

const ICON_NAMES_MAP = {
  favouritePub: 'ico-fav-filled',
  notFavouritePub: 'ico-fav'
};

export default {
  name: 'ManageFavouritesControl',
  components: { BaseButton, BaseSpriteIcon },
  props: {
    publicationId: {
      type: String,
      required: true
    }
  },
  computed: {
    iconName() {
      return this.isFavouritePublication
        ? ICON_NAMES_MAP.favouritePub
        : ICON_NAMES_MAP.notFavouritePub;
    },
    buttonLabel() {
      return this.isFavouritePublication
        ? this.$t('ManageFavouritesControl.button.remove.label')
        : this.$t('ManageFavouritesControl.button.add.label');
    },
    isButtonLabelVisible() {
      return !this.$attrs?.hasOwnProperty('icon');
    },
    isFavouritePublication() {
      return this.$store.getters[
        'FavouritePublicationsStore/isFavouritePublication'
      ](this.publicationId);
    },
    isGuestUser() {
      return this.$store.getters['UserStore/isGuestUser'];
    }
  },
  methods: {
    toggleFavourites() {
      if (this.isGuestUser) {
        this.$_openConnectPopup();
        return;
      }

      this.$store.dispatch(
        'FavouritePublicationsStore/toggleFavourites',
        this.publicationId
      );
    },
    $_openConnectPopup() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.CONNECT,
        popupContext: { initState: LoginStatesEnum.REGISTRATION }
      });
    }
  }
};
</script>

<style lang="less" src="./ManageFavouritesControl.less"></style>
