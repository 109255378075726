import imageLoaderMixin from './imageLoaderMixin.js';
import materialLoaderMixin from './materialLoaderMixin.js';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';
import TextUtils from '@shared/publication/dom-utils/text-utils.mjs';
import Locator from '@shared/publication/locator.mjs';

import ContentRequestEnum from '@/enums/ContentRequestEnum';
import ContentRequestFactory from '@/classes/factories/ContentRequestFactory';

import get from 'lodash/get';
import scrollUtils from './scrollUtils.js';

export default {
  mixins: [imageLoaderMixin, materialLoaderMixin],
  methods: {
    $_downloadTopBottomParagraphs(paraId) {
      const includeParaId = false;
      return this.$_paragraphLoader(
        ContentRequestEnum.TOP_BOTTOM,
        paraId,
        includeParaId
      );
    },
    $_downloadBottomParagraphs() {
      const lastParaId = this.lastItem.id;
      if (lastParaId === this.lastContentParagraph) {
        return Promise.resolve();
      }
      let includeParaId = false;
      return this.$_paragraphLoader(
        ContentRequestEnum.TO_BOTTOM,
        lastParaId,
        includeParaId
      );
    },
    $_downloadTopParagraphs() {
      const firstItemParaId = this.firstItem.id;
      if (firstItemParaId === this.firstContentParagraph) {
        return Promise.resolve();
      }
      let includeParaId = true;
      return this.$_paragraphLoader(
        ContentRequestEnum.TO_TOP,
        firstItemParaId,
        includeParaId
      );
    },
    async $_paragraphLoader(direction, paraId, includeParaId) {
      if (this.uploadInProgress) {
        return Promise.resolve();
      }
      this.uploadInProgress = true;
      try {
        const res = await this.contentRequestProvider(
          direction,
          paraId,
          includeParaId
        );
        this.rawItems = res;
        this.uploadInProgress = false;
      } catch (error) {
        this.uploadInProgress = false;
        return this.$_bookScrollErrorHandler(error);
      }
    },

    $_isElementVoiced(paraId) {
      return this.$store.getters['PublicationStore/isElementVoiced'](
        this.publicationId,
        paraId
      );
    },
    /**
     * @param {ContentRequestEnum.TO_TOP || ContentRequestEnum.TO_BOTTOM || ContentRequestEnum.TOP_BOTTOM} direction
     * @param {String} paraId - start paragraph of downloading content
     * @param {Boolean} includeParaId - do we need to include paraId to request
     * @return {Promise}
     */
    contentRequestProvider(direction, paraId, includeParaId) {
      const publicationId = this.publicationId;
      const PARA_NUM = this.paraNum;
      let contentRequest;
      switch (direction) {
        case ContentRequestEnum.TO_BOTTOM:
          contentRequest = ContentRequestFactory.bottomContentRequest(
            publicationId,
            paraId,
            PARA_NUM,
            includeParaId
          );
          break;
        case ContentRequestEnum.TO_TOP:
          contentRequest = ContentRequestFactory.topContentRequest(
            publicationId,
            paraId,
            PARA_NUM,
            includeParaId
          );
          break;
        case ContentRequestEnum.TOP_BOTTOM:
          contentRequest = ContentRequestFactory.topBottomContentRequest(
            publicationId,
            paraId,
            PARA_NUM
          );
          break;
        default:
          return Promise.reject(
            'Can`t download content with unsupported direction in PresentPublication: ',
            direction
          );
      }
      return this.$store.dispatch(
        'PublicationStore/getContentByContentRequest',
        contentRequest
      );
    },

    $_applyHighlight(
      paraId,
      element,
      selectionParagraphsRange,
      { stems, quotes, startLocator, endLocator }
    ) {
      const currentSelectionPara = selectionParagraphsRange.find(
        para => para.id === paraId
      );

      let highlightOffsets;
      const currentParaEndOffset = TextUtils.calculateContentStableLength(
        element
      );
      if (currentSelectionPara) {
        switch (currentSelectionPara.id) {
          case startLocator?.prefixedParagraphId:
            if (endLocator?.prefixedParagraphId === currentSelectionPara.id) {
              highlightOffsets = this.$_getHighlightOffsets(
                startLocator?.logicalCharOffset,
                endLocator?.logicalCharOffset
              );
            } else {
              highlightOffsets = this.$_getHighlightOffsets(
                startLocator?.logicalCharOffset,
                currentParaEndOffset
              );
            }
            break;
          case endLocator?.prefixedParagraphId:
            highlightOffsets = this.$_getHighlightOffsets(
              0,
              endLocator?.logicalCharOffset
            );
            break;
          default:
            highlightOffsets = this.$_getHighlightOffsets(
              0,
              currentParaEndOffset
            );
        }
      }

      try {
        scrollUtils.highlightStems(stems, element);
        scrollUtils.highlightQuotes(quotes, element);
        scrollUtils.highlightSelection(highlightOffsets, element);
      } catch (err) {
        this.$_bookScrollErrorHandler(err);
      }
    },

    $_getHighlightOffsets(start, end) {
      return {
        start: {
          logicalCharOffset: start
        },
        end: {
          logicalCharOffset: end
        }
      };
    },

    $_getCheckHeightContainer() {
      const element = get(this, '$refs.checkHeightContainer', null);
      if (!element) {
        throw new Error('Did not find checkHeightContainer.');
      }
      return element;
    },
    mGetLocatorFromHighlightSelection(selection) {
      if (selection.prefixedParagraphId) {
        return selection;
      }
      const paraId = MarkerUtils.getParaIdByBlockId(
        selection.blockId,
        this.$refs.bookScrollWrapper
      );
      return new Locator.InTextLocator(paraId, selection.logicalCharOffset);
    }
  }
};
