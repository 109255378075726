var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loginform-content login-by-email"},[_c('div',{staticClass:"loginform-fields"},[_c('BaseTextField',{staticClass:"mt-4",class:{ invalid: _vm.isFieldInvalid(_vm.$v.loginEmail) },attrs:{"label":_vm.$t('Email'),"placeholder":_vm.$t('Email.placeholder'),"type":"email","tabindex":"0","name":"loginEmail","prepend-inner-icon":"$icoEnvelop","error-messages":_vm.$t(_vm.validationOnLogin.email.errorMessage),"maxlength":_vm.emailMaxLength},on:{"input":_vm.onInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onLogin.apply(null, arguments)}},model:{value:(_vm.$v.loginEmail.$model),callback:function ($$v) {_vm.$set(_vm.$v.loginEmail, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.loginEmail.$model"}}),_vm._v(" "),_c('BaseTextField',{staticClass:"loginform-field",class:{ invalid: _vm.isFieldInvalid(_vm.$v.loginPassword) },attrs:{"label":_vm.$t('Password'),"placeholder":_vm.$t('Password'),"type":_vm.passwordType,"name":"loginPassword","prepend-inner-icon":"$icoLocked","append-icon":_vm.passwordShowIcon,"error-messages":!_vm.validationOnLogin.password.isValid
          ? _vm.$t(_vm.validationOnLogin.password.errorMessage)
          : !_vm.validationOnLogin.combination.isValid
          ? _vm.$t(_vm.validationOnLogin.combination.errorMessage)
          : !_vm.areCredentialsValid.status
          ? _vm.$t(_vm.areCredentialsValid.errorMessage)
          : null,"maxlength":_vm.passwordMaxLength},on:{"input":_vm.onInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onLogin.apply(null, arguments)},"click:append":_vm.changePasswordType},model:{value:(_vm.$v.loginPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.loginPassword, "$model", $$v)},expression:"$v.loginPassword.$model"}}),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('BaseButton',{attrs:{"color":"primary","text":""},on:{"click":_vm.resetPassword}},[_vm._v("\n        "+_vm._s(_vm.$t('Login.forgotpassword.label'))+"\n      ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"loginform-controls mt-2"},[_c('div',{on:{"click":_vm.onLogin}},[_c('BaseButton',{ref:"loginButton",staticClass:"text-none",attrs:{"color":"primary","block":"","x-large":"","elevation":"0"}},[_vm._v("\n        "+_vm._s(_vm.$t('SignIn'))+"\n      ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }