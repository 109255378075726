<template>
  <BaseWidget
    :content-image="widgetState.contentImage"
    :content-title="widgetState.contentTitle"
    :content-message="widgetState.contentMessage"
    :primary-label="widgetState.primaryLabel"
    :primary-footer-handler="widgetState.primaryFooterHandler"
  >
    <template #custom-content>
      <component
        :is="widgetState.component"
        :init-state="initWidgetState"
        @onAction="processingAction"
      />
    </template>
  </BaseWidget>
</template>
<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('Connect.vue');
import images from '@/assets/images';
import Utils from '@/services/utils/Utils';

import SignWidgetFactory from '@/classes/factories/WidgetFactory';
import BaseWidget from '@/components/base/BaseWidget/BaseWidget.vue';
import LoginState from '@/components/views/ConnectPopup/LoginState/LoginState.vue';
import LoginForgotPassword from '@/components/views/LoginForgotPassword/LoginForgotPassword';

import LoginStatesEnum from '@/enums/LoginStatesEnum';
import LoginActionsEnum from '@/enums/LoginActionsEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

export default {
  name: 'SignWidget',
  components: { BaseWidget, LoginState, LoginForgotPassword },
  props: {
    initState: {
      type: String,
      required: false,
      default: LoginStatesEnum.LOGIN_BY_EMAIL
    }
  },
  data() {
    return {
      currentState: null
    };
  },
  computed: {
    initWidgetState: {
      get() {
        return this.currentState || this.initState;
      },
      set(val) {
        this.currentState = val;
      }
    },
    widgetState() {
      const widgetState = SignWidgetFactory.createState();

      switch (this.initWidgetState) {
        case LoginStatesEnum.LOGIN_BY_EMAIL:
        case LoginStatesEnum.REGISTRATION:
          widgetState.setComponent(LoginState);
          break;
        case LoginStatesEnum.FORGOT_PASSWORD:
          widgetState.setComponent(LoginState);
          this.$store.dispatch('ManagePopupStore/openPopup', {
            name: PopupNamesEnum.CONNECT,
            popupContext: { initState: LoginStatesEnum.FORGOT_PASSWORD }
          });
          break;
        case LoginStatesEnum.FORGOT_PASSWORD_DONE:
          widgetState
            .setContentImage(images.picMail)
            .setContentTitle(this.linkSentInstruction)
            .setContentMessage(this.$t('LoginForgotPassword.contentMessage2'))
            .setPrimaryLabel(this.$t('Back to Login'))
            .setPrimaryFooterHandler(this.$_backToLogin);
          break;
        case LoginStatesEnum.REGISTRATION_DONE:
          this.$_toRegistration();
          break;
        default:
          logger.error(`Unknown state ${this.currentState}`);
          break;
      }
      return widgetState;
    },
    linkSentInstruction: function() {
      const email = this.$store.getters['LoginStore/getForgotEmail'];
      const maskedEmail = Utils.maskEmail(email);
      return this.$t('ResetPassword.instructions.text').replace(
        '<email>',
        maskedEmail
      );
    },
    isConnectPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.CONNECT
      );
    }
  },
  watch: {
    isConnectPopupOpened(val) {
      if (!val) {
        this.initWidgetState = LoginStatesEnum.LOGIN_BY_EMAIL;
      }
    }
  },
  methods: {
    processingAction(payload) {
      const { type } = payload;
      if (type === 'socialButtonClick') {
        this.$_seoRedirectRoute(AppStateEnum.THANK_YOU_FOR_REGISTRATION);
        return;
      }
      const actionMap = {
        [LoginActionsEnum.TO_FORGOT_PASSWORD]: LoginStatesEnum.FORGOT_PASSWORD,
        [LoginActionsEnum.TO_REGISTRATION]: LoginStatesEnum.REGISTRATION,
        [LoginActionsEnum.REGISTRATION_DONE]: LoginStatesEnum.REGISTRATION_DONE,
        [LoginActionsEnum.TO_FORGOT_PASSWORD_DONE]:
          LoginStatesEnum.FORGOT_PASSWORD_DONE
      };
      this.initWidgetState = actionMap[type];
    },
    $_backToLogin() {
      this.initWidgetState = LoginStatesEnum.LOGIN_BY_EMAIL;
      this.$store.commit('LoginStore/clearStore');
    },
    $_toRegistration() {
      this.$_seoRedirectRoute(AppStateEnum.VERIFY_EMAIL);
      this.$router.push({ name: AppStateEnum.VERIFY_EMAIL });
    },
    $_seoRedirectRoute(value) {
      this.$store.commit('LoginStore/setSeoRedirectRoute', value);
    }
  }
};
</script>
