<template>
  <div
    v-if="showScrubber"
    ref="scrubber"
    class="scrubber"
    :dir="direction"
    :class="[
      {
        isPlaying: isShownPlayingFilter
      },
      { dragging: isDragging },
      highlightClass,
      'book-dir-' + bookDirection
    ]"
    :style="{ top: topOffset + 'px' }"
  >
    <div class="scrubber-shape">
      <div class="control-button" :class="PLAY_BUTTON_CLASS">
        <ScrubberPlayButton />
      </div>
      <Highlight
        :direction="direction"
        :locator="currentLocator"
        :word-element="currentWordElement"
      />
      <span class="text-cover"></span>
      <div class="handle-wrapper">
        <div ref="scrubberHandler" class="handle" @mousedown="grabScrubber">
          <BaseSpriteIcon
            custom-class="wide-arrow"
            icon-name="ico-scrubber-move-icon-up-down"
          />
          <BaseSpriteIcon
            custom-class="narrow-arrow arrow-up"
            icon-name="ico-book-info-read-solid"
          />
          <BaseSpriteIcon
            custom-class="narrow-arrow arrow-down"
            icon-name="ico-book-info-read-solid"
          />
        </div>
      </div>
      <div class="scrubber-shade"></div>
    </div>
  </div>
</template>
<script>
import HighlightOptionsEnum from '@/enums/HighlightOptionsEnum';
import ScrubberEventEnum from '@/enums/ScrubberEventEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import Highlight from '@/components/views/Audio/Highlight';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import { mapGetters } from 'vuex';

import Locator from '@shared/publication/locator.mjs';
import ScrubberPlayButton from './ScrubberPlayButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import Utils from '@/services/utils/Utils';
import BookUtils from '@shared/publication/book-utils.mjs';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';
const {
  getCoordinatesFromEvent,
  getLocatorByWordElement,
  isHeader
} = MarkerUtils;

import scrollUtils from '@/components/views/BookScroll/scrollUtils.js';
import PromiseUtil from '@/services/utils/PromiseUtil';
import LayoutManager from '@/services/utils/LayoutManager';

import LoggerFactory from '@/services/utils/LoggerFactory';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
const logger = LoggerFactory.getLogger('Scrubber.vue');
const SCRUBBER_LAYOUT_ID = 'scrubberLayoutId';

const scrollDirectionEnum = {
  UP: 'up',
  DOWN: 'down'
};

const scrubberHighlightClassMap = {
  [HighlightOptionsEnum.DARKEN]: '',
  [HighlightOptionsEnum.HIGHLIGHT]: 'filled-mode',
  [HighlightOptionsEnum.UNDERLINE]: ''
};

export default {
  name: 'Scrubber',
  components: { ScrubberPlayButton, BaseSpriteIcon, Highlight },
  props: {
    paraRenderRange: Object
  },
  data() {
    const PLAY_BUTTON_CLASS = AppConstantsUtil.PLAY_BUTTON_CLASS;
    return {
      initTopOffset: null,
      correctionDelta: 0,
      PLAY_BUTTON_CLASS,
      height: 0,
      topOffset: 0,
      direction: 'auto',
      animateScroll: null,
      isDragging: false,
      deltaToScrubberTop: 0,
      mouseDownTopOffset: 0,
      currentWordElement: null,
      y: 0,
      previousParaWordElement: null
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['isDevice', 'isOsx', 'isLinux', 'isSafari']),
    bookDirection() {
      const bookLanguage = this.$store.getters[
        'PublicationStore/getBookLanguage'
      ];
      return Utils.getDirection(bookLanguage);
    },
    isViewMode() {
      return this.$store.getters['OpenParameterStore/isPreviewMode'];
    },
    showScrubber() {
      return (
        this.showScrubberWhilePreloading ||
        (this.currentParaAlignmentIndex &&
          this.currentLocator &&
          this.currentWordElement &&
          !this.isEditCompilationMode &&
          this.isDisplayScrubber)
      );
    },
    highlightClass() {
      const highlightMode = this.$store.getters[
        'ReadingSettingsStore/getHighlightMode'
      ];
      return scrubberHighlightClassMap[highlightMode];
    },

    showScrubberWhilePreloading() {
      return (
        this.$store.state.PlaybackStore.isPreloading &&
        this.$store.state.PlaybackStore.isPlaying
      );
    },

    isPlaying() {
      return this.$store.state.PlaybackStore.isPlaying;
    },

    isUserPlayAudio() {
      return this.$store.getters['PlaybackStore/isUserPlayAudio'];
    },

    isPageInDefaultScrollingMode() {
      return this.$store.getters[
        'ReadingSettingsStore/getIsPageInDefaultScrollingMode'
      ];
    },

    isShownPlayingFilter() {
      return (
        !this.isPageInDefaultScrollingMode &&
        (this.isUserPlayAudio || this.$store.state.PlaybackStore.isPreloading)
      );
    },

    isEditCompilationMode() {
      return this.$store.state.CompilationsStore.isEditMode;
    },

    isDisplayScrubber() {
      return this.$store.getters['PlaybackStore/isDisplayScrubber'];
    },

    currentLocator() {
      return this.$store.getters['PlaybackStore/getCurrentLocator'];
    },
    currentParaAlignment() {
      return this.$store.getters['PublicationStore/getParagraphAlignment'](
        this.currentBookId,
        this.currentParaId
      );
    },
    currentParaAlignmentIndex() {
      return this.$store.getters['PublicationStore/isElementVoiced'](
        this.currentBookId,
        this.currentParaId
      );
    },
    currentParaId() {
      return this.$store.state.PlaybackStore.currentParaId;
    },
    currentBookId() {
      return this.$store.state.PlaybackStore.currentBookId;
    },
    currentTime() {
      return this.$store.state.PlaybackStore.currentTime;
    },
    $_handleMousemove() {
      return async event => {
        if (this.isDragging) {
          const { clientY } = getCoordinatesFromEvent(event);
          this.y = clientY;
          this.$_refreshScrubberStyles();
          await this.checkIsNeedScrollByScrubber();
        }
      };
    },
    $_handleMouseup() {
      const self = this;
      return function(event) {
        if (self.isDragging) {
          self.$_stopAnimationIfNeed();
          const shouldRelocate = self.$_shouldRelocate(self.y);
          if (self.currentWordElement && shouldRelocate) {
            const { clientY } = getCoordinatesFromEvent(event);
            self.y = self.isViewMode
              ? clientY + self.scrollContainer.scrollTop
              : event.pageY || clientY + self.scrollContainer.scrollTop;
            self.$_refreshScrubberStyles();
            // Note: don't optimize self.isDragging!
            // self.$_refreshScrubberStyles() depends on this flag.
            // Move it before if/else causes unstable scrubber behavior on iOS
            self.isDragging = false;
            self.dropScrubber();
          } else {
            self.isDragging = false;
            const delta = self.mouseDownTopOffset - self.y;
            self.topOffset += delta;
          }
          self.$store.commit('PlaybackStore/setIsDragging', self.isDragging);
        }
      };
    },
    scrollContainer() {
      return this.$store.getters['PublicationStore/getScrollElement'];
    },
    isExtrasOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.EXTRAS_POPUP
      );
    }
  },
  watch: {
    async showScrubber(isShown) {
      if (!isShown) {
        this.$_removeTouchStartListener();
      } else {
        await this.$nextTick();
        this.$_refreshScrubberStyles();
        this.$_addTouchStartListener();
      }
    },
    currentLocator(newLocator, oldLocator) {
      this.updateCurrentWordElement(newLocator, oldLocator);
    },
    currentWordElement() {
      this.$_refreshScrubberStyles();
    },
    isDisplayScrubber(isDisplay) {
      if (isDisplay) {
        this.$_refreshScrubberStyles();
      }
    },
    paraRenderRange(newRenderRange) {
      const hasValidState =
        this.currentLocator &&
        newRenderRange.startParaId &&
        newRenderRange.endParaId;
      if (!hasValidState) {
        return;
      }
      const startSerializedLocator = MarkerUtils.prefixedParaIdToLocator(
        newRenderRange.startParaId
      );
      const endSerializedLocator = MarkerUtils.prefixedParaIdToLocator(
        newRenderRange.endParaId
      );
      const locator = Locator.deserialize(this.currentLocator);
      const startLocator = Locator.deserialize(startSerializedLocator);
      const endLocator = Locator.deserialize(endSerializedLocator);

      const isInsideRenderRange =
        !locator.precedes(startLocator) && locator.precedes(endLocator);
      if (isInsideRenderRange) {
        this.updateCurrentWordElement(this.currentLocator);
        this.$_refreshScrubberStyles();
      }
    },
    isPlaying(val) {
      if (val) {
        this.$_stopAnimationIfNeed();
      }
    },
    isShownPlayingFilter(val) {
      if (val) {
        this.initTopOffset = null;
      }
    },
    isExtrasOpened() {
      this.$_refreshScrubberStyles();
    }
  },
  mounted() {
    LayoutManager.register({
      layout: this.$_onLayoutChange,
      id: SCRUBBER_LAYOUT_ID
    });
    this.$_addClickEventListeners();
    this.$_addTouchEventListeners();
  },
  beforeDestroy() {
    this.$_removeClickEventListeners();
    this.$_removeTouchEventListeners();
    this.$_stopAnimationIfNeed();
    LayoutManager.unregister(SCRUBBER_LAYOUT_ID);
  },
  methods: {
    isChapter(locator) {
      const paraId = locator.startLocator.prefixedParagraphId;
      return this.$store.getters['BookStore/isChapter'](paraId);
    },
    grabScrubber(event) {
      try {
        event.preventDefault();
        const { clientY } = getCoordinatesFromEvent(event);
        this.deltaToScrubberTop = this.$_getDeltaToScrubberTop(clientY);
        this.mouseDownTopOffset = clientY;
        this.$store.dispatch('PlaybackStore/pause');
        this.isDragging = true;
      } catch (err) {
        logger.error(`Error while grabbing scrubber: ${err.stack}`);
        this.isDragging = false;
      }
      this.$store.commit('PlaybackStore/setIsDragging', this.isDragging);
    },
    updateCurrentWordElement(serializedLocator, oldSerializedLocator) {
      if (!serializedLocator) {
        return null;
      }
      const locator = Locator.deserialize(serializedLocator);
      const currentWordElement = BookUtils.getWordDomWrapper(locator);
      if (oldSerializedLocator) {
        const oldLocator = Locator.deserialize(oldSerializedLocator);
        const isCurrentParagraph =
          locator.startLocator.paragraphId ===
          oldLocator.startLocator.paragraphId;
        this.previousParaWordElement = isCurrentParagraph
          ? this.currentWordElement
          : null;
      }
      this.currentWordElement = currentWordElement ? currentWordElement : null;
    },
    async dropScrubber() {
      //todo: better drop strategy!
      const topY = this.y;
      const isTopInsideViewPort = this.$_checkInViewPort(topY);

      const bottomY = this.y + this.height;
      const isBottomInsideViewPort = this.$_checkInViewPort(bottomY);

      if (!isTopInsideViewPort || !isBottomInsideViewPort) {
        this.$store.commit('PlaybackStore/setCurrentParaId', null);
        await PromiseUtil.wait(500);
        const locatorOnThird = this.$store.getters[
          'PublicationStore/getThirdScreenLocator'
        ]();
        const bookId = this.currentBookId;
        this.$store.dispatch('PlaybackStore/playOnThirdScreen', {
          locatorOnThird,
          bookId
        });
        return;
      }
      const wordElement =
        this.$_getWordElementUnderScrubber() || this.currentWordElement;
      if (!wordElement) {
        return;
      }

      const paraId = wordElement.closest('[data-ww]').id;
      const isElementVoiced = this.$store.getters[
        'PublicationStore/isElementVoiced'
      ](this.currentBookId, paraId);
      if (!isElementVoiced) {
        return;
      }
      try {
        const serializedLocator = BookUtils.getSerializedLocatorFromWordElement(
          wordElement
        );
        const locator = Locator.deserialize(serializedLocator);

        await this.$store.dispatch('PlaybackStore/preloadAlignment', {
          paraId,
          bookId: this.currentBookId
        });

        const time = this.$store.getters['PublicationStore/getTimeByLocator'](
          this.currentBookId,
          locator.startLocator
        );

        this.$store.dispatch('PlaybackStore/playParagraph', {
          paraId,
          time
        });
      } catch (err) {
        const delta = this.mouseDownTopOffset - this.y;
        this.topOffset += delta;
        logger.error(`Error while preparing audio after drop scrubber: ${err}`);

        if (err.type === CustomErrorEnum.NETWORK_ERROR) {
          this.emit(CustomErrorEnum.NETWORK_ERROR, err);
          return;
        }
        this.emit(ScrubberEventEnum.ERROR, err);
      }
    },
    emit(type, data = {}) {
      this.$emit('scrubberEvent', {
        type,
        data
      });
    },
    $_stopAnimationIfNeed() {
      if (this.animateScroll) {
        this.animateScroll.stop();
        this.animateScroll = null;
        this.$_removeScrollListener();
      }
    },
    async checkIsNeedScrollByScrubber() {
      const y = this.y + this.scrollContainer.scrollTop;
      const x = this.scrollContainer.getBoundingClientRect().x;
      const viewPortParams = this.$_getInViewPortParams(x, y);
      const isInsideViewPortY =
        viewPortParams.inViewPortBottom && viewPortParams.inViewPortTop;

      if (!isInsideViewPortY && !this.animateScroll && this.isDragging) {
        const scrollDirection = !viewPortParams.inViewPortBottom
          ? scrollDirectionEnum.DOWN
          : scrollDirectionEnum.UP;
        await this.scrollByScrubber(scrollDirection);
        this.animateScroll = null;
        return this.checkIsNeedScrollByScrubber();
      }
    },
    async scrollByScrubber(scrollDirection) {
      let delta = 0;
      const ANIMATE_SCROLL_DIST = 300;
      switch (scrollDirection) {
        case scrollDirectionEnum.DOWN:
          delta = ANIMATE_SCROLL_DIST;
          break;
        case scrollDirectionEnum.UP:
          delta = -ANIMATE_SCROLL_DIST;
          break;
        default:
          break;
      }
      this.$_addScrollListener();
      await this.$_animatedScroll(delta);
      this.$_removeScrollListener();
    },
    $_animatedScroll(delta) {
      const animationElementSelector = this.$store.getters[
        'PublicationStore/getScrollElementSelector'
      ];
      const bookScroll = this.$store.getters[
        'PublicationStore/getScrollElement'
      ];
      const SCROLL_SPEED = 0.2;
      const offset = bookScroll.scrollTop + delta;
      const delayMs = Math.abs(Math.round(delta / SCROLL_SPEED));
      const { promise, animateScroll } = scrollUtils.animatedScroll(
        animationElementSelector,
        offset,
        delayMs
      );
      this.animateScroll = animateScroll;
      return promise;
    },
    $_addScrollListener() {
      const scrollListenerElement = this.$_getScrollListenerElement();
      scrollListenerElement.addEventListener('scroll', this.$_scrollListener);
    },
    $_removeScrollListener() {
      const scrollListenerElement = this.$_getScrollListenerElement();
      scrollListenerElement.removeEventListener(
        'scroll',
        this.$_scrollListener
      );
    },
    $_getScrollListenerElement() {
      return this.$store.getters['PublicationStore/getScrollListenerElement'];
    },
    $_onLayoutChange(payload) {
      const { events } = payload;
      if (events.resizing) {
        this.$_refreshScrubberStyles();
      }
    },
    $_scrollListener() {
      this.$_refreshScrubberStyles();
    },
    $_refreshScrubberStyles() {
      if (!this.showScrubber) {
        return;
      }
      this.height = this.$_getScrubberHeight();
      if (this.$refs.scrubber) {
        this.$refs.scrubber.style.height = this.height + 'px';
      }
      this.topOffset = this.$_getTopOffset();
      this.direction = this.$_getDirection();
    },
    $_addClickEventListeners() {
      window.addEventListener('mousemove', this.$_handleMousemove);
      window.addEventListener('mouseup', this.$_handleMouseup);
    },
    $_removeClickEventListeners() {
      window.removeEventListener('mousemove', this.$_handleMousemove);
      window.removeEventListener('mousemove', this.$_handleMouseup);
    },
    $_addTouchEventListeners() {
      window.addEventListener('touchmove', this.$_handleMousemove);
      window.addEventListener('touchend', this.$_handleMouseup);
    },
    $_removeTouchEventListeners() {
      window.removeEventListener('touchmove', this.$_handleMousemove);
      window.removeEventListener('touchend', this.$_handleMouseup);
      this.$_removeTouchStartListener();
    },
    $_addTouchStartListener() {
      this.$refs.scrubberHandler.addEventListener(
        'touchstart',
        this.grabScrubber
      );
    },
    $_removeTouchStartListener() {
      if (!this.$refs.scrubberHandler) {
        return;
      }
      this.$refs.scrubberHandler.removeEventListener(
        'touchstart',
        this.grabScrubber
      );
    },
    $_addCordovaPauseListeners() {
      document.addEventListener('pause', this.$_cordovaPauseHandler);
    },
    $_removeCordovaPauseListeners() {
      document.removeEventListener('pause', this.$_cordovaPauseHandler);
    },
    $_cordovaPauseHandler() {
      this.$store.dispatch('PlaybackStore/pause');
    },

    $_getScrubberHeight() {
      if (!this.currentWordElement) {
        return 0;
      }
      const compStyles = window.getComputedStyle(this.currentWordElement);
      const height = parseInt(compStyles.fontSize);
      return Number.isNaN(height) ? 0 : height;
    },
    $_getDeltaToScrubberTop(topOffset) {
      const scrubber = this.$refs.scrubber;
      if (!scrubber) {
        logger.warn(`No scrubber found in DOM`);
        return 0;
      }
      const scrubberTop = this.$_getElementYPosition(scrubber);
      return topOffset - scrubberTop;
    },
    $_shouldRelocate(mouseUpTopOffset) {
      const shouldRelocateDelta = this.height / 2;
      const offsetDelta = Math.abs(this.mouseDownTopOffset - mouseUpTopOffset);
      return offsetDelta >= shouldRelocateDelta;
    },
    $_checkInViewPort(y) {
      const { x } = this.$store.getters['PublicationStore/getViewportRect']();
      return this.$store.getters['PublicationStore/checkPointInViewPort'](x, y);
    },
    $_getInViewPortParams(x, y) {
      return this.$store.getters['PublicationStore/getInViewPortParams'](x, y);
    },
    $_getTopOffset() {
      if (
        !this.scrollContainer ||
        !this.currentWordElement ||
        !this.$_inDom(this.currentWordElement)
      ) {
        return 0;
      }
      const y = this.isDragging ? this.y : this.$_getElementYPosition();
      let topOffset = this.$store.getters[
        'PublicationStore/getElementTopOffset'
      ](y);
      const shouldGetLiftedOffset =
        this.isDevice || this.isOsx || this.isLinux || this.isSafari;

      let originalTopOffset = topOffset;

      let CORRECTION_COEFFICIENT = 1;
      const isBreakWord = this.currentWordElement.innerText.includes('-');
      const isBreakOffset =
        this.previousParaWordElement?.offsetHeight !==
        this.currentWordElement.offsetHeight;

      if (isBreakWord && isBreakOffset) {
        CORRECTION_COEFFICIENT = 2;
      }

      if (shouldGetLiftedOffset) {
        topOffset = this.$_getLiftedTopOffset(topOffset);
      }

      if (originalTopOffset === topOffset) {
        topOffset += this.$refs.scrubber
          ? (this.currentWordElement.offsetHeight / CORRECTION_COEFFICIENT -
              this.$refs.scrubber.getBoundingClientRect().height) /
            2
          : 0;
      }
      return this.isDragging ? topOffset - this.deltaToScrubberTop : topOffset;
    },

    $_getElementYPosition() {
      return this.$store.getters['PublicationStore/getElementYPos'](
        this.currentWordElement
      );
    },
    // workaround for cropped text for .ilm-header and .ilm-title on devices (problem caused by font-family Garamond1689 & GoudyHandtooled)
    $_getLiftedTopOffset(topOffset) {
      const breakingFonts = ['Garamond1689', 'GoudyHandtooled'];
      const _isHeader = isHeader(this.currentWordElement.closest('[data-ww]'));
      const computedStyles = window.getComputedStyle(this.currentWordElement);
      const fontFamily = computedStyles.getPropertyValue('font-family');
      const fontSize = parseInt(computedStyles['font-size'].replace('px', ''));

      const isBreakingFont = breakingFonts.some(font =>
        fontFamily.includes(font)
      );
      if (!_isHeader || !isBreakingFont) {
        return topOffset;
      }
      const lineHeightOffset = isNaN(fontSize)
        ? this.currentWordElement.offsetHeight
        : fontSize;
      return topOffset - lineHeightOffset / 4;
    },
    $_getDirection() {
      if (!this.currentWordElement || !this.$_inDom(this.currentWordElement)) {
        return 'ltr';
      }
      const defaultDir = 'ltr';
      const closestDirElement = this.currentWordElement.closest('[dir]');
      if (!closestDirElement) {
        return defaultDir;
      }
      return closestDirElement.getAttribute('dir');
    },
    $_inDom(element) {
      const rects = element.getBoundingClientRect();
      return rects.x !== 0 || rects.y !== 0 || rects.height !== 0;
    },
    $_getWordElementUnderScrubber() {
      if (!this.currentWordElement) {
        return null;
      }
      const currentParaElementRect = this.currentWordElement
        .closest('[data-ww]')
        .getBoundingClientRect();

      const scrubber = this.$refs['scrubber'];
      if (!currentParaElementRect || !scrubber) {
        return null;
      }
      const scrubberRect = scrubber.getBoundingClientRect();
      const directions = ['ltr', 'rtl'];
      let elementByCoordinates = null;

      for (let i = 0; i < directions.length; i++) {
        const dir = directions[i];
        const paraBeginXCoord = this.$_getParaBeginXCoord(
          dir,
          currentParaElementRect
        );
        elementByCoordinates = this.$_getElementByScrubberCoordinates(
          paraBeginXCoord,
          scrubberRect,
          elementByCoordinates
        );
        let paraDir = '';
        if (elementByCoordinates) {
          const para = elementByCoordinates.closest('[dir]');
          paraDir = para.getAttribute('dir');
        }
        if (paraDir === dir) {
          break;
        }
      }
      return elementByCoordinates;
    },
    $_getElementByScrubberCoordinates(
      paraBeginXCoord,
      scrubberRect,
      elementByCoordinates
    ) {
      const coordinates = {
        x: paraBeginXCoord,
        y: scrubberRect.y + Math.round(scrubberRect.height / 2)
      };
      elementByCoordinates = this.$_getElementByCoordinates(
        coordinates.x,
        coordinates.y
      );
      if (!elementByCoordinates) {
        elementByCoordinates = this.$_findNextWordElement(coordinates);
      }
      const dropCapShift = this.$_calculateDropCapShift(
        elementByCoordinates,
        scrubberRect
      );
      if (dropCapShift) {
        elementByCoordinates = this.$_getElementByCoordinates(
          coordinates.x + dropCapShift,
          coordinates.y
        );
      }
      return elementByCoordinates;
    },
    $_calculateDropCapShift(elementByCoordinates, scrubberRect) {
      let dropCapShift = 0;
      const dropCapEl =
        elementByCoordinates.closest(AppConstantsUtil.DROP_CAP_SELECTOR) ||
        elementByCoordinates.querySelector(AppConstantsUtil.DROP_CAP_SELECTOR);
      if (!dropCapEl) {
        return dropCapShift;
      }
      const dropCapRects = dropCapEl.getBoundingClientRect();
      const middleOfDropCap =
        Math.ceil(dropCapRects.height / 4) + Math.ceil(dropCapRects.top);
      if (
        scrubberRect.top > middleOfDropCap &&
        scrubberRect.top < dropCapRects.bottom
      ) {
        dropCapShift = Math.ceil(dropCapRects.width);
      }
      return dropCapShift;
    },
    $_getElementByCoordinates(x, y) {
      const element = BookUtils.findWordDomWrapperByPosition(x, y);
      return element;
    },
    $_findNextWordElement({ x, y }) {
      const paraElement = BookUtils.findParaItemByPoint(x, y);
      if (!paraElement) {
        return null;
      }
      const currentParaId = paraElement.id;
      const bookId = this.currentBookId;
      const nextVoicedParaId = this.$store.getters[
        'BookStore/getNextVoicedParaId'
      ](bookId, currentParaId);
      const nextWord = document.querySelector(`#${nextVoicedParaId} [data-wi]`);
      if (!nextWord) {
        return null;
      }
      const locator = getLocatorByWordElement(nextWord);
      return BookUtils.getWordDomWrapper(Locator.deserialize(locator));
    },
    $_getParaBeginXCoord(direction, currentParaElementRect) {
      if (direction === 'ltr') {
        return currentParaElementRect.x;
      } else {
        const rightCorrection = 1;
        return (
          currentParaElementRect.x +
          currentParaElementRect.width -
          rightCorrection
        );
      }
    }
  }
};
</script>
<style lang="less" src="./Scrubber.less"></style>
