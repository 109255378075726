import TooltipAlignEnum from '@/enums/TooltipAlignEnum';

class TooltipModel {
  constructor(buildData) {
    this.customClass = buildData.customClass || '';
    this.text = buildData.text || '';
    this.align = buildData.align;
    this.targetElement = buildData.targetElement || null;
    this.xOffset = buildData.targetElement || 0;
  }

  getAlign() {
    return this.align;
  }

  setAlign(align) {
    this.align = align;
  }

  setElement(targetElement) {
    this.targetElement = targetElement;
  }

  setTooltipText(text) {
    this.text = text;
  }

  setXOffset(xOffset) {
    this.xOffset = xOffset;
  }

  setCustomClass(customClass) {
    this.customClass = customClass;
  }
}

class TooltipModelBuilder {
  setCustomClass(customClass) {
    this.customClass = customClass;
    return this;
  }

  setText(text) {
    this.text = text;
    return this;
  }

  setAlign(align) {
    this.align = align;
    return this;
  }

  setTargetElement(targetElement) {
    this.targetElement = targetElement;
    return this;
  }

  setXOffset(xOffset) {
    this.xOffset = xOffset;
    return this;
  }

  build() {
    return new TooltipModel(this);
  }
}

function create(text, align, customClass, targetElement) {
  return new TooltipModel(text, align, customClass, targetElement);
}

function createEmptyBottomInfoTooltip() {
  const builder = new TooltipModelBuilder();
  return builder
    .setCustomClass('info-tooltip')
    .setAlign(TooltipAlignEnum.BOTTOM)
    .build();
}

function createEmptyTopInfoTooltip() {
  const builder = new TooltipModelBuilder();
  return builder
    .setCustomClass('info-tooltip')
    .setAlign(TooltipAlignEnum.TOP)
    .build();
}

function createEmptyBottomControllTooltip() {
  const builder = new TooltipModelBuilder();
  return builder
    .setCustomClass('context-menu')
    .setAlign(TooltipAlignEnum.BOTTOM)
    .build();
}

function createEmptyTopControllTooltip() {
  const builder = new TooltipModelBuilder();
  return builder
    .setCustomClass('context-menu')
    .setAlign(TooltipAlignEnum.TOP)
    .build();
}

export default {
  create,
  createEmptyBottomInfoTooltip,
  createEmptyTopInfoTooltip,
  createEmptyBottomControllTooltip,
  createEmptyTopControllTooltip
};
