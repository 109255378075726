<template>
  <div class="loginform-content">
    <PopupContent
      class="forgot-password-block"
      :content-image="contentImage"
      :content-title="$t('ResetPassword.header.text')"
      :content-message="contentMessage"
    >
      <template #apply-additional-block>
        <BaseTextField
          v-model.trim="$v.email.$model"
          class="mt-2"
          :class="{ invalid: isFieldInvalid($v.email) }"
          :placeholder="$t('ResetPassword.Email.label')"
          type="email"
          tabindex="0"
          prepend-inner-icon="$icoEnvelopOpen"
          :maxlength="emailMaxLength"
          :error-messages="errorMessages"
          dense
          @input="onInput"
          @keydown.enter="sendEmail"
        >
        </BaseTextField>
      </template>
      <template #apply-additional-content>
        <BaseButton
          class="text-none"
          color="primary"
          dark
          block
          large
          @click="sendEmail"
        >
          {{ $t('SendLink') }}
        </BaseButton>
      </template>
    </PopupContent>
  </div>
</template>

<script>
import BaseTextField from '@/components/base/BaseTextField/BaseTextField';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import PopupContent from '@/components/base/PopupContent/PopupContent.vue';
import images from '@/assets/images';
import LoginActionsEnum from '@/enums/LoginActionsEnum';
import {
  required as validateReq,
  email as validateEmail
} from 'vuelidate/lib/validators';
import ValidationErrorMessages from '@/enums/ValidationErrorMessagesEnum';
import { emailMaxLength } from '@/services/ValidationService';

export default {
  name: 'LoginForgotPassword',
  components: {
    PopupContent,
    BaseTextField,
    BaseButton
  },
  props: {
    step: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      validationOnSend: {
        email: {
          isValid: true,
          errorMessage: ''
        },
        emailExistence: {
          isValid: true,
          errorMessage: ValidationErrorMessages.EMAIL_IS_NOT_FOUND
        }
      },
      forgotTooltip: this.$t('App.Login.forgotTooltip.Text'),
      emailMaxLength,
      passwordChangedIcon: 'ico-check',
      contentImage: images.picLock,
      contentMessage: this.$t('LoginForgotPassword.contentMessage1')
    };
  },
  validations: {
    email: {
      required: validateReq,
      email: validateEmail
    }
  },
  computed: {
    email: {
      set(val) {
        this.$store.commit('LoginStore/setForgotEmail', val);
      },
      get() {
        return this.$store.getters['LoginStore/getForgotEmail'];
      }
    },
    errorMessages() {
      return this.$t(this.emailExistenceRule || this.emailValidRule);
    },
    emailExistenceRule() {
      return !this.validationOnSend.emailExistence.isValid
        ? this.validationOnSend.emailExistence.errorMessage
        : '';
    },
    emailValidRule() {
      return !this.validationOnSend.email.isValid
        ? this.validationOnSend.email.errorMessage
        : '';
    }
  },
  mounted() {
    this.$store.dispatch('LoginStore/cleanForgotPasswordInfo');
  },
  methods: {
    async sendEmail() {
      this.$_resetLazyValidation();
      this.$v.email.$touch();
      if (!this.$_isValidOnSend(this.$v.email)) {
        return;
      }
      const email = this.email;
      const result = await this.$store.dispatch('LoginStore/forgotPassword', {
        email
      });
      if (result?.data?.status === 'OK') {
        this.toForgotPasswordDone();
        return;
      }
      this.validationOnSend.emailExistence.isValid = false;
    },
    onInput: function() {
      this.$_resetLazyValidation();
    },
    isFieldInvalid: function(fieldValidator) {
      return fieldValidator.$invalid && fieldValidator.$dirty;
    },
    $_isValidOnSend: function(validationInfo) {
      if (validationInfo.$invalid) {
        this.validationOnSend.email.isValid = false;
        this.validationOnSend.email.errorMessage = !validationInfo.required
          ? ValidationErrorMessages.EMAIL_IS_EMPTY
          : ValidationErrorMessages.EMAIL_IS_INVALID;
        return false;
      }
      return true;
    },
    $_resetLazyValidation: function() {
      this.validationOnSend.email.isValid = true;
      this.validationOnSend.emailExistence.isValid = true;
    },
    toForgotPasswordDone() {
      this.$_emit(LoginActionsEnum.TO_FORGOT_PASSWORD_DONE);
    },
    $_emit(type) {
      this.$emit('onAction', {
        type
      });
    }
  }
};
</script>

<style src="./LoginForgotPassword.less" lang="less"></style>
