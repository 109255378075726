<template>
  <div>
    <component
      :is="material.component"
      v-for="material in paraMaterials"
      :key="material.id"
      :material-view="material"
      @materialEvent="materialEventHandler"
    />
  </div>
</template>

<script>
import MaterialPositions from '@/enums/MaterialPositions';

const BEFORE_PARA_GETTER_NAME = 'MaterialsStore/beforeParaMaterialsByParaId';
const AFTER_PARA_GETTER_NAME = 'MaterialsStore/afterParaMaterialsByParaId';

export default {
  name: 'ParagraphMaterials',
  components: {},
  props: {
    paraMaterialsType: {
      required: true,
      type: String
    },
    paraId: {
      required: true,
      type: String
    }
  },
  data() {
    const materialsGetterName =
      this.paraMaterialsType === MaterialPositions.BEFORE_PARAGRAPH
        ? BEFORE_PARA_GETTER_NAME
        : AFTER_PARA_GETTER_NAME;
    return {
      materialsGetterName
    };
  },
  computed: {
    paraMaterials() {
      return this.$store.getters[this.materialsGetterName](this.paraId);
    }
  },
  methods: {
    materialEventHandler(event) {
      this.$emit('materialEvent', event);
    }
  }
};
</script>

<style lang="less" src="./ParagraphMaterials.less"></style>
