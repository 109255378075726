export default {
  ERROR: 'compilationError',
  OPEN_MENU: 'openMenu',
  DUPLICATE_SELECTION: 'duplicateSelection',
  SHARE_SELECTION: 'shareSelection',
  MOVE_TO_SECTION: 'moveToSection',
  REMOVE: 'remove',
  EDIT: 'edit',
  MOVE_UP: 'moveUp',
  MOVE_DOWN: 'moveDown'
};
