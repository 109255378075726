<template>
  <div class="section-view">
    <div v-if="isEditMode" class="move-controls">
      <div>
        <button @click="moveUp">
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        </button>
        <button @click="moveDown">
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        </button>
      </div>
    </div>
    <div v-if="isEditMode" class="section-controls-wrapper">
      <BaseTextField
        v-model="editContent"
        counter="50"
        maxlength="50"
        :dense="false"
        @change="save"
      />
      <div class="section-controls">
        <BaseButton icon color="error" @click="remove">
          <BaseSpriteIcon icon-name="ico-delete" />
        </BaseButton>
      </div>
    </div>
    <div v-else ref="sectionContent" class="section-content">
      <p :id="paraId" data-before="0" data-ww>
        {{ sectionText }}
      </p>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField';
import CompilationEventsEnum from '@/enums/CompilationEventsEnum.js';
import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import images from '@/assets/images';

export default {
  components: {
    BaseButton,
    BaseSpriteIcon,
    BaseTextField
  },
  props: {
    bookId: {
      type: String,
      required: true
    },
    paraId: {
      type: String,
      required: true
    },
    clientSortId: {
      type: String,
      required: true,
      default: ''
    },
    content: {
      type: String,
      required: true
    }
  },
  data() {
    const element = document.createElement('div');
    element.innerHTML = this.content;
    const sectionText = element.textContent;

    return {
      editContent: sectionText,
      sectionText,
      oldPara: null
    };
  },
  computed: {
    isEditMode() {
      return this.$store.getters['CompilationsStore/getIsEditMode'];
    }
  },
  watch: {
    isEditMode(isEdit) {
      if (isEdit) {
        this.$_prepareEditContent();
      } else {
        this.save();
      }
    }
  },
  methods: {
    moveUp() {
      this.emit(CompilationEventsEnum.MOVE_UP, {
        paraId: this.paraId,
        clientSortId: this.clientSortId
      });
    },
    moveDown() {
      this.emit(CompilationEventsEnum.MOVE_DOWN, {
        paraId: this.paraId,
        clientSortId: this.clientSortId
      });
    },
    edit() {
      this.$_prepareEditContent();
    },
    save() {
      if (!this.isEditMode) {
        return;
      }
      this.$store.dispatch('CompilationsStore/updateSectionTitle', {
        paraId: this.paraId,
        compilationId: this.bookId,
        title: this.editContent
      });
    },
    remove() {
      const contentImage = images.picGarbage;
      const title = this.$t(
        'CompilationDeleteContentItemPopup.contentTitle.section.label',
        { contentItemTitle: `"${this.editContent}"` }
      );
      const message = this.$t(
        'CompilationDeleteContentItemPopup.contentMessage.label'
      );
      const primaryLabel = this.$t('Popup.button.delete.label');
      const type = CompilationSelectionTypes.SECTION;
      const data = { compilationId: this.bookId, paraId: this.paraId };
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.CONFIRM_POPUP,
        popupContext: {
          contentImage,
          title,
          message,
          primaryLabel,
          type,
          data
        }
      });
    },
    emit(type, data) {
      this.$emit('editControls', {
        type,
        data
      });
    },
    $_prepareEditContent() {
      this.editContent = this.sectionText;
    }
  }
};
</script>

<style lang="less">
@import '~@/assets/styles/base.less';

.section-view {
  flex-grow: 1;
  min-height: 3.5em;

  @media @narrow {
    .compilation-edit & {
      margin-top: -45px;
    }
  }
}

.section-controls-wrapper {
  margin: 5px 40px 0;

  input {
    .night-theme-template & {
      color: #999;
    }
  }
}

.move-controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 0 20px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;

  @media @narrow {
    padding: 0 10px;
  }

  > div {
    pointer-events: none;
    position: relative;
    margin-top: 28px;

    @media @narrow {
      margin-top: 14px;
    }

    button {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        svg {
          opacity: 0.5;
        }
      }

      &:first-of-type {
        transform: rotate(180deg);
      }
    }

    svg {
      transition: opacity 0.15s ease;
      padding-bottom: 5px;
      width: 24px;
      height: 24px;
    }
  }

  .hidden-controls {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    pointer-events: all;
    align-items: center;
  }

  svg {
    fill: var(--black-color);

    .night-theme-template & {
      fill: #fff;
    }
  }
}

.section-controls {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;

  @media @narrow {
    padding: 0 10px;
  }

  button {
    margin-top: 52px;
    margin-inline-end: 12px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;

    @media @normal {
      margin-inline-end: 4px;
    }

    @media @narrow {
      margin-top: 36px;
      margin-inline-end: 2px;
    }

    svg {
      fill: var(--primary-color);
      transition: opacity 0.15s ease;

      .night-theme-template & {
        fill: #fff;
      }
    }

    &:hover {
      svg {
        opacity: 0.5;
      }
    }
  }
}
</style>
