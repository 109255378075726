var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Tooltip',{attrs:{"custom-class":_vm.tooltip.customClass,"align":_vm.tooltip.align,"target-element":_vm.tooltip.targetElement,"x-offset":_vm.tooltip.xOffset}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tooltip.text)}})]),_vm._v(" "),_c('div',{ref:"fab",staticClass:"play-button-wrapper",class:[
      {
        'is-playing': _vm.isAudioPlaying,
        disabled: _vm.isLoading
      },
      _vm.BUTTON_CLASS
    ],attrs:{"id":"fab"},on:{"click":_vm.toggleAudio,"mousemove":_vm.showTooltip,"mouseleave":_vm.hideTooltip}},[_c('BaseSpriteIcon',{class:{ 'btn-play': !_vm.isAudioPlaying },attrs:{"icon-name":_vm.iconName}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }