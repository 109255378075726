<template>
  <div
    v-if="showScroll"
    ref="bookScrollWrapper"
    v-hotkey.stop="keymap"
    :data-book-version="bookVersion"
    :data-reading-area="readingArea"
    :style="readSettingsStyles"
    :class="[
      getWidthStyle,
      isFontSizeIncrease,
      SCROLL_BLOCK_CLASS,
      {
        'hide-play-buttons': hidePlayButtons,
        'extras-component-open': isExtrasComponentOpen,
        'hide-paragraph-play-buttons': isAudioPlaying
      }
    ]"
    :dir="contentDirection"
    @click="onScrollClick"
  >
    <slot name="context-menu"></slot>
    <VirtualScrollList
      ref="virtualScrollList"
      :start-position="startPosition"
      :end-position="endPosition"
      :item-class-name="itemClassName"
      :total-items="totalItems"
      :scroll-class-name="scrollClassName"
      :publication-id="publicationId"
      :dom-elements-number="domElementsNumber"
      :start-render-count="startRenderCount"
      :view-port-height="viewPortHeight"
      :debounce="100"
      :open-offset="openOffset"
      :onscroll="onScroll"
      :scroll-selector="scrollSelector"
      :wclass="SCROLL_LIST_CLASS"
      :show-suggested-books="showSuggestedBooks"
      :item-wrapper-class="SCROLL_ITEM_WRAPPER_CLASS"
      :start-index="startIndex"
      :para-id="paraId"
      :scroll-para-id="scrollParaId"
      :required-locator="requiredLocator"
      :scroll-items="items"
      :book-direction="bookDirection"
      :start-item-index="startItemIndex"
      :current-playing-para-id="currentPlayingParaId"
      :is-audio-playing="isAudioPlaying"
      :class="BOOK_SCROLL_CLASS_NAME"
      @VirtualScrollListEvent="virtualScrollListEventHandler"
    >
      <template #meta-block>
        <slot name="meta-block" />
      </template>
      <template
        v-if="isCompilation && !isCompilationEditMode"
        #empty-placeholder
        ><CompilationEmptyPlaceholder
      /></template>
      <template #side-bar>
        <slot name="side-bar" />
      </template>
      <template v-if="showCompilationControls" #compilation-controls>
        <CompilationControls
          :book-id="publicationId"
          @editControls="editControlsHandler"
        />
      </template>
      <template #next-button>
        <button
          v-if="hasNextBook"
          class="default-button btn text-none pub-nav"
          @click="openNextCollectionItem()"
        >
          {{ $t('NextPublicationButtonTemplate.nextDocument.label') }}
        </button>
      </template>
      <template #prev-button>
        <button
          v-if="hasPrevBook"
          class="default-button btn text-none pub-nav"
          @click="openPrevCollectionItem()"
        >
          {{ $t('PrevPublicationButtonTemplate.previousDocument.label') }}
        </button>
      </template>
      <template #scrubber>
        <Scrubber
          :para-render-range="paraRenderRange"
          @scrubberEvent="scrubberEventHandler"
        />
      </template>
      <template #text-selection>
        <slot name="text-selection" />
      </template>
    </VirtualScrollList>
  </div>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('BookScroll.vue');
import VirtualScrollList from './VirtualScrollList/VirtualScrollList.vue';

import contentLoaderMixin from './contentLoaderMixin.js';
import footnoteMixin from '@/components/views/BookScroll/footnoteMixin.js';

import Utils from '@/services/utils/Utils';
import scrollUtils from './scrollUtils.js';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import LayoutManager from '@/services/utils/LayoutManager';
import BuildValidateService from '@/services/BuildValidateService';
// import SpriteLoader from '@/services/SpritesService';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';
import PublicationLogicService from '@/services/PublicationLogic/PublicationLogicService';
import SelectionConstantUtils from '@shared/publication/selection/SelectionConstantUtils.mjs';

import AppModes from '@/enums/AppModeEnum';
import BookScrollEnum from '@/enums/BookScrollEnum';

import MaterialPositions from '@/enums/MaterialPositions';
import VirtualScrollListEventsEnum from '@/enums/VirtualScrollListEventsEnum';
import CompilationEventsEnum from '@/enums/CompilationEventsEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

import SVGSprite from '@/assets/sprite.symbol.svg';

import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';
import CompilationControls from '@/components/views/BookScroll/CompilationControls';
import CompilationEmptyPlaceholder from '@/components/views/CompilationEmptyPlaceholder/CompilationEmptyPlaceholder';
import Locator from '@shared/publication/locator.mjs';
import Scrubber from '@/components/views/Audio/Scrubber';
import PublicationErrorHandlingLogicService from '@/services/PublicationLogic/PublicationErrorHandlingLogicService';

const SCROLL_WRAPPER_CLASS = 'book-scroll-wrapper';
const marginNotesOn = 'margin-notes-on';
const SCROLL_LIST_CLASS = AppConstantsUtil.SCROLL_LIST_CLASS;
const SCROLL_ITEM_WRAPPER_CLASS =
  SelectionConstantUtils.SCROLL_ITEM_WRAPPER_CLASS;
const PLAY_BUTTON_CLASS = 'paragraph-play-buttons';

// TODO think about implementation by component
const playIconLink = `${SVGSprite}#ico-book-info-read-solid`;
const playButtonTemplate = `<span class="${PLAY_BUTTON_CLASS}" title="Play">
    <span class="paragraph-play-button ">
      <svg xmlns="http://www.w3.org/2000/svg" class="svg-ico" viewBox="0 0 24 24">
        <use href="${playIconLink}" xlink:href="${playIconLink}"></use>
      </svg>
    </span>
  </span>`;

const BOOK_SCROLL_LAYOUT_ID = 'BookScrollLayoutId';
let contentRequestPromise = null;

export default {
  name: 'BookScroll',
  components: {
    VirtualScrollList,
    Scrubber,
    CompilationControls,
    CompilationEmptyPlaceholder
    // ParagraphMaterials
  },
  mixins: [contentLoaderMixin, footnoteMixin],
  data() {
    let viewPortHeight = 1024;
    if (process.client) {
      viewPortHeight = this.calcViewPortHeight();
    }
    const scrollWrapperClass = SCROLL_WRAPPER_CLASS;

    return {
      isMounted: false,
      isFirstStableAfterMount: true,
      paraRenderRange: {
        startParaId: '',
        endParaId: ''
      },
      itemClassName: AppConstantsUtil.SCROLL_ITEM_CLASS,
      BOOK_SCROLL_CLASS_NAME: AppConstantsUtil.BOOK_SCROLL_CLASS_NAME,
      SCROLL_BLOCK_CLASS: AppConstantsUtil.SCROLL_BLOCK_CLASS,
      SCROLL_LIST_CLASS: SCROLL_LIST_CLASS,
      SCROLL_ITEM_WRAPPER_CLASS,
      currentAudioPara: null,
      contentDirection: 'ltr',
      uploadInProgress: false,
      componentDisplayRules: {
        // to hide components if error occurred
        metaBlock: true
      },
      viewPortHeight,
      startIndex: -1,
      initialIndex: 0,
      startItemIndex: 0,
      items: [],
      allItems: [],
      startPosition: 0,
      endPosition: 0,
      totalItems: 0,
      lastItem: {},
      firstItem: {},
      scrollWrapperClass,
      beforeParaMaterials: MaterialPositions.BEFORE_PARAGRAPH,
      afterParaMaterials: MaterialPositions.AFTER_PARAGRAPH,
      showScroll: true,
      positioningReady: false,
      waitRenderTimeout: null,
      currentScrollStatus: null,
      offsetFromScrollStart: null,
      endSubscription: null,
      debugMode: false, //to use check if fonts were downloaded in time
      highlightNotAdded: true,
      scrollParaId: null
    };
  },
  async fetch() {
    logger.info(
      `fetch data for book scroll paraId: ${this.paraId} publicationId: ${this.publicationId}`
    );
    await this.$_loadBookContent(this.publicationId);
    await this.initScroll(this.paraId);
    this.emit(BookScrollEnum.DATA_FETCHED);
    this.initialIndex = this.startIndex;
  },
  computed: {
    // data for autotests
    bookVersion() {
      return this.$store.getters['BookStore/getBookVersion'];
    },
    readingArea() {
      const locatorRange = this.$store.getters['ProgressStore/getReadingArea'];
      return locatorRange && typeof locatorRange.serialize === 'function'
        ? locatorRange.serialize()
        : '';
    },
    //

    publicationId() {
      return this.$store.getters['OpenParameterStore/getPublicationId'];
    },
    slug() {
      return this.$store.getters['OpenParameterStore/getSlug'];
    },
    paraId() {
      return (
        this.openParaId ||
        this.$store.getters['PublicationStore/getFirstContentParagraph'](
          this.publicationId
        )
      );
    },
    openParaId() {
      return this.$store.getters['OpenParameterStore/getParaId'];
    },
    requiredLocator() {
      const requiredLocator = this.$store.getters[
        'OpenParameterStore/getRequiredLocator'
      ];
      return requiredLocator || '';
    },
    contentHighlight() {
      const highlight = this.$store.getters[
        'OpenParameterStore/getTextHighlight'
      ];
      return highlight;
    },
    openOffset() {
      const openOffset = this.$store.getters[
        'OpenParameterStore/getOpenOffset'
      ];
      return openOffset || 0;
    },
    paraNum() {
      const renderedParaNum = this.$store.getters[
        'OpenParameterStore/getRenderedParagraphNumber'
      ];
      return renderedParaNum;
    },
    readSettingsStyles() {
      return this.$store.getters[
        'ReadingSettingsStore/getReadingSettingsStyles'
      ];
    },
    metaBlockHeigth() {
      return this.$store.getters['PublicationStore/getMetaBlockHeigth'];
    },
    domElementsNumber() {
      return this.paraNum;
    },
    startRenderCount() {
      return this.$store.getters['OpenParameterStore/getStartRenderCount'];
    },
    halfDomElementsNumber() {
      return Math.round(this.domElementsNumber / 2);
    },

    bookItemPosition() {
      return this.$store.getters['PublicationStore/getBookItemPosition'](
        this.publicationId
      );
    },

    hasPrevBook() {
      return (
        this.isMounted &&
        !this.isViewMode &&
        Boolean(this.bookItemPosition.prevBookSlug)
      );
    },
    hasNextBook() {
      return (
        this.isMounted &&
        !this.isViewMode &&
        Boolean(this.bookItemPosition.nextBookSlug)
      );
    },

    userBookLevel() {
      return this.$store.getters['AssessmentStore/getUserBookLevel'];
    },

    isViewMode() {
      return this.$store.getters['OpenParameterStore/isPreviewMode'];
    },
    currentPlayingParaId() {
      if (!this.currentScrubberLocator || !this.isAudioPlaying) {
        return '';
      }
      const locator = Locator.deserialize(this.currentScrubberLocator);
      return locator.startLocator.prefixedParagraphId;
    },
    isContentReady() {
      return this.$store.getters['PublicationStore/isContentReady'];
    },

    narrowPara() {
      return this.$store.getters['ReadingSettingsStore/getExpandedNotesMode'];
    },

    isCompilation() {
      return this.$store.getters['PublicationStore/isCompilationOpen'];
    },

    showCompilationControls() {
      return this.isCompilation;
    },

    firstContentParagraph() {
      return this.$store.getters['PublicationStore/getFirstContentParagraph'](
        this.publicationId
      );
    },
    lastContentParagraph() {
      return this.$store.getters['PublicationStore/getLastContentParagraph'](
        this.publicationId
      );
    },
    currentScrubberLocator() {
      return this.$store.getters['PlaybackStore/getCurrentLocator'];
    },
    getWidthStyle() {
      return this.narrowPara ? marginNotesOn : '';
    },
    isFontSizeIncrease() {
      const fontSize = this.$store.getters['ReadingSettingsStore/getFontSize'];
      return fontSize > 100 ? 'font-size-increased' : '';
    },
    sidebarClass() {
      return this.narrowPara ? 'min-sidebar' : 'margin-notes-on';
    },
    isExtrasOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.EXTRAS_POPUP
      );
    },
    isExtrasComponentOpen() {
      const mediaSize = this.$store.getters['MediaDetectorStore/mediaSize'];
      return this.isExtrasOpened && mediaSize.wide;
    },
    showOnLoad() {
      return !this.showScroll;
    },
    hidePlayButtons() {
      return this.currentAudioPara !== null;
    },
    isAudioPlaying() {
      return this.$store.getters['PlaybackStore/isAudioPlaying'];
    },
    isCompilationEditMode() {
      return this.$store.state.CompilationsStore.isEditMode;
    },
    limitedAccess() {
      const publicationId = this.publicationId;
      return this.$store.getters['PublicationStore/isLimitedAccess'](
        publicationId
      );
    },
    isSuggestionsEnabled() {
      return this.$store.getters['ContextStore/isSuggestionsEnabled'];
    },
    isSuggestedBlockVisible() {
      return this.$store.getters['LibraryStore/isSuggestedBlockVisible'];
    },
    showSuggestedBooks() {
      return this.isSuggestedBlockVisible && !this.isCompilation;
    },
    bookDirection() {
      const bookLanguage = this.$store.getters[
        'PublicationStore/getBookLanguage'
      ];
      return Utils.getDirection(bookLanguage);
    },
    keymap() {
      return {
        'ctrl+c': this.handleCopyEvent,
        'meta+c': this.handleCopyEvent,
        'shift+space': this.handleSpacePressedHotkey,
        space: this.handleSpacePressedHotkey
      };
    },

    scrollSelector() {
      return this.$store.getters['PublicationStore/getScrollElementSelector'];
    },

    scrollClassName() {
      return this.$store.getters['PublicationStore/getScrollClassName'];
    },
    isSamePublication() {
      return this.$store.getters['OpenParameterStore/isSamePublication'](
        this.$route
      );
    },
    hasAlignmentMap() {
      return this.$store.getters['PublicationStore/hasAlignmentMap'];
    }
  },

  watch: {
    isExtrasComponentOpen() {
      this.emit(BookScrollEnum.REOPEN_ON_ROTATE, {});
    },
    async hasAlignmentMap(has) {
      if (has && this.isSamePublication) {
        this.$_fillItems();
      }
    },
    async openParaId(newParaId) {
      if (!newParaId || !this.isSamePublication) {
        return;
      }
      this.highlightNotAdded = true;
      await this.initScroll(newParaId);
    },
    contentHighlight: {
      deep: true,
      async handler(newValue, oldValue) {
        if (!newValue) {
          return;
        }
        if (newValue.isEmpty) {
          this.$_undecorateSelection();
        }
        const prefixedParagraphId =
          newValue.selection?.start?.prefixedParagraphId;
        if (prefixedParagraphId) {
          this.highlightNotAdded = true;
        }
        const isLocator =
          newValue?.selection?.start instanceof Locator.InTextLocator &&
          newValue?.selection?.end instanceof Locator.InTextLocator;
        const oldPrefixedParagraphId =
          oldValue?.selection?.start?.prefixedParagraphId;

        const needNavigateToHighlight =
          isLocator &&
          prefixedParagraphId &&
          oldPrefixedParagraphId &&
          prefixedParagraphId === oldPrefixedParagraphId;

        if (needNavigateToHighlight) {
          const openLocator = new Locator.InTextRangeLocator(
            newValue.selection.start,
            newValue.selection.end
          );
          await this.initScroll(openLocator.toJSON());
        }
      }
    },

    'currentScrollStatus.start': function() {
      if (!this.currentScrollStatus) {
        return;
      }
      this.offsetFromScrollStart = this.currentScrollStatus.offset;
    },

    isCompilationEditMode() {
      this.updateScrollParams();
      this.$_createItems(this.startIndex);
    },

    limitedAccess(isLimited, old) {
      if (old === null) {
        return;
      }
      this.$_applyLimitedAccessLogic(isLimited, this.startIndex);
      if (this.paraId && !this.isViewMode) {
        this.initScroll(this.paraId);
      }
    },
    startIndex(newIndex, oldIndex) {
      if (oldIndex !== -1) {
        return;
      }
      this.initialIndex = this.startIndex;
      if (this.limitedAccess) {
        this.$_setItemsDisabled(this.startIndex);
      }
    }
  },
  async mounted() {
    try {
      this.isMounted = true;
      logger.info('Start handle mounted');
      const isCompactData = this.$store.getters[
        'PublicationStore/isCompactData'
      ];
      if (isCompactData) {
        await this.$_loadBookContent(this.publicationId);
        await this.initScroll(this.paraId);
      }
      if (process.client) {
        LayoutManager.register({
          layout: this.$_onLayout,
          id: BOOK_SCROLL_LAYOUT_ID
        });
        this.endSubscription = this.$store.subscribeAction({
          after: action => {
            switch (action.type) {
              case 'CompilationsStore/removeSelection':
              case 'CompilationsStore/appendSelectionHandler':
              case 'CompilationsStore/duplicateSelection':
              case 'CompilationsStore/appendSection':
              case 'CompilationsStore/moveToSection':
              case 'CompilationsStore/moveSelectionUp':
              case 'CompilationsStore/moveSelectionDown':
              case 'PublicationStore/saveMetaData':
                if (this.isCompilation) {
                  this.updateScrollParams();
                  this.$_createItems(this.startIndex);
                }
                break;
            }
          }
          // after: this.$_materialsActionsHandler
        });
      }

      this.initialIndex = this.startIndex;
      if (this.limitedAccess) {
        this.$_setItemsDisabled(this.startIndex);
      }
      logger.info('Handled mounted');
    } catch (error) {
      logger.error(`Get error on scroll mounted: ${error}`);
      this.$_bookScrollErrorHandler(error);
    }
  },
  destroyed() {
    LayoutManager.unregister(BOOK_SCROLL_LAYOUT_ID);
    clearTimeout(this.waitRenderTimeout);
    if (this.endSubscription) {
      this.endSubscription();
    }
  },
  methods: {
    $_applyLimitedAccessLogic(isLimited, startIndex) {
      if (isLimited) {
        this.$_setItemsDisabled(startIndex);
      } else {
        this.$_setItemsEnabled();
      }
    },
    async $_loadBookContent(publicationId) {
      try {
        if (!publicationId) {
          return;
        }
        await this.$store.dispatch('PublicationStore/initPublicationContent', {
          publicationId
        });
      } catch (error) {
        logger.fatal(`get error on load content assets error: ${error.stack}`);
        PublicationErrorHandlingLogicService.openBookErrorHandling(
          this.$store,
          this.$t.bind(this),
          error
        );
        return false;
      }
    },
    handleCopyEvent(event) {
      this.emit(BookScrollEnum.COPY_EVENT, { event });
    },
    handleSpacePressedHotkey(event) {
      this.emit(BookScrollEnum.SPACE_PRESSED_EVENT, { event });
    },
    openPrevCollectionItem() {
      this.$_openPublication(this.bookItemPosition.prevBookSlug);
    },
    openNextCollectionItem() {
      this.$_openPublication(this.bookItemPosition.nextBookSlug);
    },
    async $_openPublication(slug) {
      await PublicationNavigateLogicService.openPublication(
        this.$store,
        this.$router,
        { slug }
      );
    },

    updateScrollParams() {
      this.allItems = this.$store.getters['PublicationStore/getParagraphItems'];
      this.totalItems = this.allItems.length;
      this.lastItem = this.getLastItem();
      this.firstItem = this.getFirstItem();
      this.$_fillItems();
    },

    calcStartPosition(startIndex) {
      return Math.max(startIndex - this.halfDomElementsNumber, 0);
    },

    calcEndPosition(startIndex) {
      const newEndPosition = startIndex + this.halfDomElementsNumber;
      return Math.min(newEndPosition, this.totalItems);
    },

    calcItemPosition(startIndex) {
      this.startPosition = this.calcStartPosition(startIndex);
      this.endPosition = this.calcEndPosition(startIndex);
    },

    $_onLayout(layoutContext) {
      this.$_recalculateCanShowMarginNotes();
      this.$_updateScrollPositionIfNeed(layoutContext);
    },

    $_recalculateCanShowMarginNotes() {
      this.$store.commit('ReadingSettingsStore/recalculateCanShowMarginNotes');
    },

    $_updateScrollPositionIfNeed(layoutContext) {
      const orienting = layoutContext?.events?.orienting;
      if (orienting) {
        this.emit(BookScrollEnum.REOPEN_ON_ROTATE, {});
      }
    },

    $_fillItems() {
      const appMode = this.$store.getters['ContextStore/appModeGetter'];
      const isReader = appMode === AppModes.READER;
      for (
        let index = this.firstItem.index;
        //todo thre
        index <= this.lastItem.index;
        index++
      ) {
        const scrollItem = this.allItems[index];
        if (!scrollItem?.hasContent) {
          continue;
        }
        if (process.client) {
          this.$_setItemMaterials(scrollItem);
        }
        const shouldInjectPlaybutton =
          isReader && this.$_isElementVoiced(scrollItem.id);
        if (shouldInjectPlaybutton) {
          this.$_injectParagraphPlaybutton(scrollItem);
        }
      }
    },

    $_injectParagraphPlaybutton(scrollItem) {
      scrollItem.paragraph = scrollItem.paragraph.replace(
        /<span class="block-pb">\s*<span class="block-pb is-animated"><\/span>\s*<\/span>/,
        () => playButtonTemplate
      );
    },

    getLastItem() {
      //todo thre
      for (let index = this.allItems.length - 1; index >= 0; index--) {
        const item = this.allItems[index];
        if (item.hasContent) {
          return item;
        }
      }
      return {};
    },

    getFirstItem() {
      const indexWithContext = this.allItems.findIndex(item => item.hasContent);
      return indexWithContext !== -1 ? this.allItems[indexWithContext] : {};
    },
    scrubberEventHandler(payload) {
      const { type, data } = payload;
      this.emit(type, data);
    },

    editControlsHandler(payload) {
      const { type, data } = payload;
      switch (type) {
        case CompilationEventsEnum.OPEN_MENU:
          this.$_openCompilationEditMenu(data);
          break;
        case CompilationEventsEnum.MOVE_UP:
          this.$_moveParaUp({ paraId: data.paraId });
          break;
        case CompilationEventsEnum.MOVE_DOWN:
          this.$_moveParaDown({ paraId: data.paraId });
          break;

        default:
          break;
      }
    },

    $_openCompilationEditMenu(data) {
      this.emit(BookScrollEnum.OPEN_COMPILATION_EDIT_MENU, data);
    },

    async $_moveParaUp(payload) {
      const { paraId, clientSortId } = payload;
      await this.$store.dispatch('CompilationsStore/moveSelectionUp', {
        compilationId: this.publicationId,
        paraId,
        clientSortId
      });
    },

    async $_moveParaDown(payload) {
      const { paraId, clientSortId } = payload;
      await this.$store.dispatch('CompilationsStore/moveSelectionDown', {
        compilationId: this.publicationId,
        paraId,
        clientSortId
      });
    },

    $_createItems(startIndex) {
      this.calcItemPosition(startIndex);
      this.items = this.allItems.slice(this.startPosition, this.endPosition);
    },

    $_setItemsDisabled(startIndex) {
      if (startIndex === -1) {
        return;
      }
      this.$store.dispatch('BookStore/setPublicationContentDisabled', {
        startIndex
      });
    },

    $_setItemsEnabled() {
      this.$store.dispatch('BookStore/setPublicationContentEnabled');
    },

    async initScroll(paraId) {
      logger.info(`initScroll by paraId: ${paraId}`);
      if (this.$store.state.PublicationStore.isScrollStabled) {
        this.showScroll = false;
      }
      this.positioningReady = false;

      await this.fillScroll(paraId);
      if (process.client) {
        this.$_recalculateCanShowMarginNotes();
      }
      if (this.$store.state.PublicationStore.isScrollStabled) {
        this.showScroll = true;
      }
      this.positioningReady = true;
      logger.info('Scroll inited');
    },

    async fillScroll(paraId) {
      try {
        this.scrollParaId = paraId;
        if (this.isContentReady) {
          if (this.uploadInProgress) {
            await contentRequestPromise;
          }
          contentRequestPromise = this.$_downloadTopBottomParagraphs(paraId);
          await contentRequestPromise;
        } else {
          logger.warn(
            `Publication content not ready: ${paraId} publicationId:${this.publicationId}`
          );
        }
      } catch (error) {
        logger.error(
          `Get error on content request in fill scroll error: ${error}`
        );
        this.$_bookScrollErrorHandler(error);
        return;
      }
      this.updateScrollParams();
      const normalizedParaId = MarkerUtils.locatorToParaId(paraId);
      this.startIndex = scrollUtils.getStartIndexByParaId(
        this.allItems,
        normalizedParaId
      );
      this.$_applyLimitedAccessLogic(this.limitedAccess, this.startIndex);
      this.$_createItems(this.startIndex);
      this.startItemIndex = this.items.findIndex(
        item => item.id === normalizedParaId
      );
      if (process.server) {
        BuildValidateService.isEmptyParagraphs(this.items, this.publicationId);
        this.$store.dispatch('PublicationStore/compactStore', {
          contentStatIndex: 0,
          contentEndIndex: 0
        });
        if (this.publicationId) {
          const excludeBookIds = [this.publicationId];
          this.$store.dispatch('LibraryStore/reset', { excludeBookIds });
        }
      }

      if (process.client) {
        this.waitRenderTimeout = setTimeout(async () => {
          this.$_afterRenderProcessing();
        }, 200);
      }
    },

    async $_afterRenderProcessing() {
      if (this.debugMode) {
        await this.$_checkIfFontsAreDownloaded();
      }
      contentRequestPromise = null;
      this.$_applyImages();
    },

    $_stableProcessing(data) {
      const isMetaBlockInViewport = this.isMetaBlockInViewport(data);
      if (!this.publicationId || !this.paraId || data.startIndex === -1) {
        return;
      }
      this.updateParaRenderRange();
      this.emit(BookScrollEnum.STABLE, {
        scrollSelector: this.scrollSelector,
        isMetaBlockInViewport,
        isScrolling: false,
        isFirstStableAfterMount: this.isFirstStableAfterMount
      });
      this.isFirstStableAfterMount = false;

      if (this.highlightNotAdded) {
        this.highlightNotAdded = false;
        this.applyHighlight(this.$refs.bookScrollWrapper);
      }
      this.$refs.bookScrollWrapper.addEventListener(
        'click',
        this.$_scrollWrapperEventHandler
      );
    },

    $_handleStopScrolling(data) {
      this.$_applyImages();
      this.emit(BookScrollEnum.STOP, data);
    },

    $_processMaterialEvent(data) {
      this.emit(BookScrollEnum.MATERIAL_EVENT, data);
    },

    calcViewPortHeight() {
      const applicationToolbar =
        document.querySelector('.application-toolbar') || {};
      const progressBar = document.querySelector('.progress-toolbar') || {};
      const fixedElementHeight =
        (applicationToolbar.offsetheight || 0) +
        (progressBar.offsetHeight || 0);
      return window.innerHeight - fixedElementHeight;
    },

    virtualScrollListEventHandler(payload) {
      const { type, data } = payload;
      switch (type) {
        case VirtualScrollListEventsEnum.STABLE_SCROLL:
          this.$_stableProcessing(data);
          return;
        case VirtualScrollListEventsEnum.MATERIAL_EVENT:
          this.$_processMaterialEvent(data);
          return;
        case VirtualScrollListEventsEnum.SECTION_EVENT:
          this.$_sectionEventHandler(data);
          return;
        case VirtualScrollListEventsEnum.STOP_SCROLLING:
          this.$_handleStopScrolling(data);
          return;
        default:
          break;
      }
      this.emit(payload);
    },

    isShownMetablockInsideScroll(item) {
      return item.isFirst && this.componentDisplayRules.metaBlock;
    },

    $_getParaIdByIndex(publicationId, paraIndex) {
      return this.$store.getters['PublicationStore/getParaIdByIndex'](
        publicationId,
        paraIndex
      );
    },

    $_scrollWrapperEventHandler(event) {
      event.preventDefault();
      this.$_playAudioHandler(event);
      this.$_compilationSelectionLinkHandler(event);
      this.$_toggleFootnote(event);
    },

    $_sectionEventHandler(data) {
      switch (data.type) {
        case CompilationEventsEnum.MOVE_UP:
          this.$_moveParaUp({
            paraId: data.data.paraId,
            clientSortId: data.data.clientSortId
          });
          break;
        case CompilationEventsEnum.MOVE_DOWN:
          this.$_moveParaDown({
            paraId: data.data.paraId,
            clientSortId: data.data.clientSortId
          });
          break;
      }
    },

    async $_compilationSelectionLinkHandler(event) {
      const linkElement = event.target.closest(
        `.${AppConstantsUtil.SELECTION_LINK_CLASS}`
      );
      if (!linkElement) {
        return;
      }
      const publicationId = linkElement.dataset.publicationId;
      const locator = linkElement.dataset.locator;
      let slug = linkElement.dataset.slug;
      slug = slug === 'undefined' ? publicationId : slug;

      await PublicationNavigateLogicService.openPublication(
        this.$store,
        this.$router,
        {
          slug,
          paragraphId: locator,
          highlight: {
            selectionString: linkElement.dataset.selection
          }
        }
      );
    },

    async $_playAudioHandler(event) {
      const paraId = this.$_findAudioParaId(event.target);
      if (!paraId) {
        return;
      }
      if (!this.$_isParaDisabled(paraId)) {
        this.currentAudioPara = paraId;
        try {
          await PublicationLogicService.playAudioFromParaId(this.$store, {
            paraId
          });
        } catch (error) {
          this.emit(BookScrollEnum.ERROR, error);
        }
      } else {
        this.$store.dispatch('UserStore/openLimitedAccessPopup');
      }
    },
    $_isParaDisabled(paraId) {
      return this.$store.getters['PublicationStore/isParagraphDisabled'](
        paraId
      );
    },
    $_findAudioParaId(element) {
      const playButton = this.$_getPlayButton(element);
      if (!playButton) {
        return null;
      }

      const paragraphSelector = MarkerUtils.getContentElementSelector();
      const paragraph = playButton.closest(paragraphSelector);
      const id = paragraph.getAttribute('id');
      const isParaId = id && id.indexOf('para_') !== -1;

      return isParaId ? id : null;
    },

    $_getPlayButton(element) {
      if (!element) {
        return null;
      }
      let currentElement = element;
      let isAudioButtonClick = false;

      while (
        currentElement &&
        !currentElement.classList.contains(SCROLL_LIST_CLASS)
      ) {
        isAudioButtonClick =
          isAudioButtonClick ||
          currentElement.classList.contains(PLAY_BUTTON_CLASS);

        if (isAudioButtonClick) {
          return currentElement;
        } else {
          currentElement = currentElement.parentElement;
          continue;
        }
      }

      return null;
    },

    $_toggleFootnote(event) {
      const anchor = event.target;
      if (!this.expandedFootnotesMode) {
        this.$_displayFootnote(anchor, this.$refs.bookScrollWrapper);
      }
    },

    async $_applyImages() {
      try {
        const scrollContainer = this.$refs.bookScrollWrapper;
        if (!scrollContainer) {
          return;
        }
        await this.$_setImageSrc(scrollContainer);
      } catch (error) {
        this.$_bookScrollErrorHandler(error);
      }
    },
    $_bookScrollErrorHandler(error) {
      this.emit(BookScrollEnum.ERROR, error);
    },

    emit(type, data) {
      let searchWidget;
      if (process.client) {
        searchWidget = document.querySelector(
          '.integrated.search-widget > .is-popup-displayed'
        );
      }
      if (!searchWidget) {
        const scrollEvent = {
          type,
          data
        };
        this.$store.commit('PublicationStore/setBookScrollEvent', scrollEvent);
        this.$emit('BookScrollEvent', scrollEvent);
      }
    },
    onScroll(event, data) {
      // if (![this.publicationId, this.slug].includes(this.$route.params.slug)) {
      //   return;
      // }
      const isMetaBlockInViewport = this.isMetaBlockInViewport(data);
      const isScrolling = this.currentScrollStatus
        ? this.currentScrollStatus.offset - data.offset !== 0
        : false;
      this.updateParaRenderRange();
      this.emit(BookScrollEnum.ON_READING_AREA_CHANGE, {
        isMetaBlockInViewport,
        isTooFar: data.isTooFar,
        isScrolling
      });
      if (data.isTooFar) {
        contentRequestPromise = this.contentRequestPromise
          ? this.contentRequestPromise
          : Promise.resolve();
        this.currentScrollStatus = data;
        return contentRequestPromise.then(() => {
          return this.$_tooFarHandler(data);
        });
      }
      if (data.renderDirection) {
        this.$_createItems(data.startIndex);
      }

      const contentRequestFn = this.$_getContentRequestFn(data);

      if (contentRequestFn && !contentRequestPromise) {
        contentRequestPromise = contentRequestFn()
          .then(() => {
            this.updateScrollParams();
          })
          .catch(error => {
            logger.error(
              `Get error on get content on scroll event error: ${error}`
            );
            this.$_bookScrollErrorHandler(error);
          })
          .finally(() => {
            contentRequestPromise = null;
          });
      }
      this.$_applyImages();
      this.currentScrollStatus = data;
      return contentRequestPromise;
    },
    updateParaRenderRange() {
      this.paraRenderRange = {
        startParaId: this.items[0]?.id,
        endParaId: this.items[this.items.length - 1]?.id
      };
    },
    applyHighlight(scrollContainer) {
      const highlight = this.contentHighlight;
      if (!highlight || highlight.isEmpty || !scrollContainer) {
        return;
      }
      try {
        const startLocator = highlight.getStartLocator(
          scrollContainer,
          this.$store
        );
        const endLocator = highlight.getEndLocator(
          scrollContainer,
          this.$store
        );

        const selectionParagraphsRange = this.$store.getters[
          'BookStore/getParagraphsRange'
        ](startLocator.prefixedParagraphId, endLocator.prefixedParagraphId);

        const highlightedAttr = 'highlighted';
        const paraphs = scrollContainer.querySelectorAll(
          `[data-before]:not([${highlightedAttr}])`
        );
        const stems = highlight?.stems || [];
        const quotes = highlight?.quotes || [];

        paraphs.forEach(paragraph => {
          const paraId = paragraph.getAttribute('id');
          this.$_applyHighlight(paraId, paragraph, selectionParagraphsRange, {
            stems,
            quotes,
            startLocator,
            endLocator
          });
          paragraph.setAttribute(highlightedAttr, '');
        });
      } catch (error) {
        logger.warn(`Get error with highlight: ${error}`);
      }
    },

    isMetaBlockInViewport(scrollData) {
      return scrollData.offset < this.metaBlockHeigth;
    },
    onScrollClick(event) {
      this.emit(BookScrollEnum.ON_ITEM_CLICK, {
        event
      });
    },
    materialEventHandler(payload) {
      this.emit(BookScrollEnum.MATERIAL_EVENT, payload);
    },
    $_getContentRequestFn(scrollData) {
      const { itemSlotPositions, renderDirection } = scrollData;
      const itemDelta = 40;
      const bottomDelta =
        this.lastItem.index - itemSlotPositions.bottomItemSlotIndex;
      if (
        !contentRequestPromise &&
        bottomDelta !== 0 &&
        renderDirection === 'D' &&
        bottomDelta < itemDelta
      ) {
        return this.$_downloadBottomParagraphs;
      }
      const topDelta =
        itemSlotPositions.topItemSlotIndex - this.firstItem.index;
      if (
        !contentRequestPromise &&
        topDelta !== 0 &&
        renderDirection === 'U' &&
        topDelta < itemDelta
      ) {
        return this.$_downloadTopParagraphs;
      }
      return null;
    },
    async $_tooFarHandler(scrollData) {
      let newStartIndex = Math.round(
        (scrollData.offset * this.totalItems) / scrollData.height
      );
      if (!newStartIndex) {
        newStartIndex = 0;
      }
      const paraId = this.$_getParaIdByIndex(this.publicationId, newStartIndex);
      await this.fillScroll(paraId);
    },
    async $_checkIfFontsAreDownloaded() {
      try {
        await this.$_downloadTopBottomParagraphs(this.paraId);
        const paragraphs = await this.modificateParagraphs(this.rawItems);
        paragraphs.forEach((para, index) => {
          if (para.height !== this.allItems[index].height) {
            logger.warn('Fonts were downloaded too late');
            return;
          }
        });
      } catch (error) {
        logger.error(`Get error on check fonts is downloaded error: ${error}`);
        this.$_bookScrollErrorHandler(error);
      }
    },
    $_undecorateSelection() {
      const scrollContainer = this.$refs.bookScrollWrapper;
      if (scrollContainer) {
        scrollUtils.undecorateSearchResults(scrollContainer);
      }
    }
  }
};
</script>

<style lang="less" src="./BookScroll.less"></style>
