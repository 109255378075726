<template>
  <div class="loginform-content">
    <div class="loginform-fields">
      <BaseTextField
        v-model.trim="$v.fullName.$model"
        class="mt-4"
        :class="{ invalid: isFieldInvalid($v.fullName) }"
        :label="$t('RegisterUserProfile.profile.name.label')"
        :placeholder="$t('RegisterUserProfile.profile.name.label')"
        type="text"
        tabindex="0"
        name="registrationFullName"
        :error-messages="$t(validationOnRegister.name.errorMessage)"
        prepend-inner-icon="$icoProfile"
        autocomplete="new-password"
        :maxlength="nameMaxLength"
        @input="onInput"
        @keydown.enter="register"
      />
      <BaseTextField
        v-model.trim="$v.email.$model"
        class="loginform-field"
        :label="$t('Email')"
        :placeholder="$t('Email')"
        type="email"
        name="registrationEmail"
        prepend-inner-icon="$icoEnvelop"
        :error-messages="emailErrorMessage"
        autocomplete="new-password"
        :maxlength="emailMaxLength"
        @input="onInput"
        @keydown.enter="register"
      />
      <BaseTextField
        v-model="$v.password.$model"
        class="loginform-field"
        :class="{ invalid: isFieldInvalid($v.password) }"
        :label="$t('Password')"
        :placeholder="$t('Password')"
        :type="passwordType"
        name="registrationPassword"
        prepend-inner-icon="$icoLocked"
        :append-icon="passwordShowIcon"
        :error-messages="$t(validationOnRegister.password.errorMessage)"
        autocomplete="new-password"
        :maxlength="passwordMaxLength"
        @input="onInput"
        @keydown.enter="register"
        @click:append="changePasswordType"
      />
    </div>
    <div class="loginform-controls mt-2">
      <BaseButton color="primary" block x-large elevation="0" @click="register"
        >{{ $t('SignUp') }}
      </BaseButton>
      <div
        v-if="shouldShowContentMessage"
        class="login-links-block text-center mt-2"
        v-html="contentMessage"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('Registration.vue');

import QueryParamService from '@/services/QueryParamService';

import LoginActionsEnum from '@/enums/LoginActionsEnum';
import { required, email } from 'vuelidate/lib/validators';
import ValidationErrorMessages from '@/enums/ValidationErrorMessagesEnum';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField.vue';
import {
  isWithoutSpaces,
  isNameValid,
  passwordMaxLength,
  nameMaxLength,
  hasPasswordValidFormat,
  emailMaxLength
} from '@/services/ValidationService';

export default {
  name: 'Registration',
  components: {
    BaseButton,
    BaseTextField
  },
  data() {
    return {
      validationOnRegister: {
        name: {
          isValid: true,
          errorMessage: ''
        },
        email: {
          isValid: true,
          errorMessage: ''
        },
        password: {
          isValid: true,
          errorMessage: ''
        },
        emailExistence: {
          isValid: true,
          errorMessage: ValidationErrorMessages.EMAIL_IS_EXISTS
        }
      },
      passwordMaxLength,
      nameMaxLength,
      emailMaxLength,
      isPasswordShown: false
    };
  },
  validations: {
    fullName: {
      required,
      isNameValid
    },
    email: {
      required,
      email
    },
    password: {
      required,
      hasPasswordValidFormat,
      isWithoutSpaces
    }
  },
  computed: {
    ...mapGetters('ContextStore', ['isDevice']),
    shouldShowContentMessage() {
      const brand = this.$store.getters['ContextStore/brand'];
      return brand !== BrandsEnum.FFA || !this.isDevice;
    },
    emailErrorMessage() {
      if (!this.validationOnRegister.email.isValid) {
        return this.$t(this.validationOnRegister.email.errorMessage);
      } else if (!this.validationOnRegister.emailExistence.isValid) {
        return this.$t(this.validationOnRegister.emailExistence.errorMessage);
      }
      return null;
    },
    contentMessage() {
      return this.$store.getters['LoginStore/getContentMessage']();
    },
    fullName: {
      set(val) {
        this.$store.commit('LoginStore/setRegistrationFullName', val);
      },
      get() {
        return this.$store.getters['LoginStore/getRegistrationFullName'];
      }
    },
    email: {
      set(val) {
        this.$store.commit('LoginStore/setRegistrationEmail', val);
      },
      get() {
        return this.$store.getters['LoginStore/getRegistrationEmail'];
      }
    },
    password: {
      set(val) {
        this.$store.commit('LoginStore/setRegistrationPassword', val);
      },
      get() {
        return this.$store.getters['LoginStore/getRegistrationPassword'];
      }
    },
    passwordShowIcon() {
      return this.isPasswordShown ? '$hidePasswordIcon' : '$showPasswordIcon';
    },
    passwordType() {
      return this.isPasswordShown ? 'text' : 'password';
    }
  },
  created() {
    this.$_prefillUserEmail();
  },
  mounted() {
    this.$_clearFields();
  },
  methods: {
    changePasswordType() {
      this.isPasswordShown = !this.isPasswordShown;
    },
    async register() {
      this.$_resetLazyValidation();
      if (!this.$_isValidOnRegister()) {
        return;
      }

      try {
        const res = await this.$store.dispatch(
          'LoginStore/applyRegistrationData'
        );
        if (res) {
          this.$emit('onAction', {
            type: LoginActionsEnum.REGISTRATION_DONE
          });
        }
      } catch (err) {
        if (err && err.status && err.status !== 200) {
          logger.error(err);
        }
        this.validationOnRegister.emailExistence.isValid = false;
      }
    },
    onInput() {
      this.$_resetLazyValidation();
    },
    isFieldInvalid(fieldValidator) {
      return fieldValidator.$invalid && fieldValidator.$dirty;
    },
    $_prefillUserEmail() {
      const query = QueryParamService.getAllParams(this.$router);
      const subscribeEmail = query.subscribeEmail;
      if (!subscribeEmail) {
        return;
      }
      this.email = subscribeEmail;
    },
    $_resetLazyValidation() {
      this.validationOnRegister.email.isValid = true;
      this.validationOnRegister.password.isValid = true;
      this.validationOnRegister.name.isValid = true;
      this.validationOnRegister.emailExistence.isValid = true;
    },
    $_isValidOnRegister() {
      const validationInfo = this.$v;
      validationInfo.$touch();

      if (validationInfo.$invalid) {
        if (validationInfo.email.$invalid) {
          this.validationOnRegister.email.isValid = false;
          this.validationOnRegister.email.errorMessage = !validationInfo.email
            .required
            ? ValidationErrorMessages.EMAIL_IS_EMPTY
            : ValidationErrorMessages.EMAIL_IS_INVALID;
        } else {
          this.validationOnRegister.email.isValid = true;
          this.validationOnRegister.email.errorMessage = '';
        }
        if (validationInfo.password.$invalid) {
          this.validationOnRegister.password.isValid = false;
          this.validationOnRegister.password.errorMessage = !validationInfo
            .password.required
            ? ValidationErrorMessages.PASSWORD_IS_EMPTY
            : ValidationErrorMessages.PASSWORD_IS_INCORRECT;
        } else {
          this.validationOnRegister.password.isValid = true;
          this.validationOnRegister.password.errorMessage = '';
        }
        if (validationInfo.fullName.$invalid) {
          this.validationOnRegister.name.isValid = false;
          this.validationOnRegister.name.errorMessage = !validationInfo.fullName
            .required
            ? ValidationErrorMessages.NAME_IS_EMPTY
            : ValidationErrorMessages.NAME_IS_NON_LATIN;
        } else {
          this.validationOnRegister.name.isValid = true;
          this.validationOnRegister.name.errorMessage = '';
        }
        return false;
      }
      return true;
    },
    $_clearFields() {
      this.fullName = '';
      this.email = '';
      this.password = '';
    }
  }
};
</script>

<style lang="less" src="./Registration.less"></style>
