<template>
  <div>
    <Tooltip
      :custom-class="tooltip.customClass"
      :align="tooltip.align"
      :target-element="tooltip.targetElement"
      :x-offset="tooltip.xOffset"
    >
      <span v-html="tooltip.text" />
    </Tooltip>
    <div
      id="fab"
      ref="fab"
      :class="[
        {
          'is-playing': isAudioPlaying,
          disabled: isLoading
        },
        BUTTON_CLASS
      ]"
      class="play-button-wrapper"
      @click="toggleAudio"
      @mousemove="showTooltip"
      @mouseleave="hideTooltip"
    >
      <BaseSpriteIcon
        :class="{ 'btn-play': !isAudioPlaying }"
        :icon-name="iconName"
      />
    </div>
  </div>
</template>

<script>
import GoogleAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import Tooltip from '@/components/views/Tooltip/Tooltip.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import TooltipFactory from '@/classes/factories/TooltipFactory';
import FloatingPlayButtonEventEnum from '@/enums/FloatingPlayButtonEventEnum';
const PLAY_ICON = 'ico-book-info-read-solid';
const PAUSE_ICON = 'ico-stop-solid';
export default {
  components: {
    Tooltip,
    BaseSpriteIcon
  },
  props: {
    disabled: { type: Boolean, default: false }
  },
  data() {
    const tooltip = TooltipFactory.createEmptyTopControllTooltip();
    const BUTTON_CLASS = AppConstantsUtil.PLAY_BUTTON_CLASS;
    this.isPlaying = false;
    const isDevice = this.$store.getters['ContextStore/isDevice'];
    return {
      timeoutId: null,
      tooltip,
      BUTTON_CLASS,
      isDevice,
      tooltipTimeoutId: null,
      callPlayAudio: false
    };
  },
  computed: {
    iconName() {
      return this.isAudioPlaying ? PAUSE_ICON : PLAY_ICON;
    },
    isAudioPlaying() {
      return this.$store.state.PlaybackStore.isPlaying;
    },
    currentParaId: function() {
      return this.$store.state.PlaybackStore.currentParaId;
    },
    isLoading: function() {
      return (
        (!this.isAudioPlaying &&
          this.$store.getters['ContextStore/getIsScrolling']) ||
        this.callPlayAudio
      );
    }
  },
  watch: {
    isAudioPlaying: function() {
      this.callPlayAudio = false;
      clearTimeout(this.timeoutId);
      this.shouldSkipNextToggle = false;
    }
  },
  methods: {
    toggleAudio() {
      if (this.shouldSkipNextToggle || this.disabled) {
        return;
      }
      if (this.isAudioPlaying) {
        GoogleAnalyticsUtils.trackEvent('book', 'pausePlayFAB');
        this.$store.dispatch('PlaybackStore/pause');
      } else {
        GoogleAnalyticsUtils.trackEvent('book', 'startPlayFAB');
        this.playAudio();
      }
      this.shouldSkipNextToggle = true;
      this.$_checkIsAudioPlayingAfterStart();
    },
    $_checkIsAudioPlayingAfterStart() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.shouldSkipNextToggle = this.isAudioPlaying;
      }, 500);
    },
    playAudio() {
      this.callPlayAudio = true;
      this.$store.commit('PublicationStore/setFabButtonPressed', true);
      this.$emit('floatingPlayButtonEvent', {
        type: FloatingPlayButtonEventEnum.PLAY_AUDIO
      });
    },

    showTooltip() {
      const fab = this.$refs.fab;
      const fabRect = fab.getBoundingClientRect();
      const xOffset = (fabRect.left + fabRect.right) / 2;
      this.tooltip.setElement(fab);
      this.tooltip.setTooltipText(
        this.$t('ProgressToolbar.AudioButton.Tooltip')
      );
      this.tooltip.setXOffset(xOffset);
      if (this.isDevice) {
        this.$_startHideTooltip();
      }
    },
    hideTooltip() {
      this.tooltip.setElement(null);
    },
    $_startHideTooltip() {
      if (this.tooltipTimeoutId) {
        clearTimeout(this.tooltipTimeoutId);
      }
      this.tooltipTimeoutId = setTimeout(() => {
        this.hideTooltip();
      }, 2000);
    },
    calcThirdOfScreen() {
      return this.$store.getters['PublicationStore/getThirdScreenLocator']();
    }
  }
};
</script>

<style lang="less" src="./FloatingPlayButton.less"></style>
