var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showScrubber)?_c('div',{ref:"scrubber",staticClass:"scrubber",class:[
    {
      isPlaying: _vm.isShownPlayingFilter
    },
    { dragging: _vm.isDragging },
    _vm.highlightClass,
    'book-dir-' + _vm.bookDirection
  ],style:({ top: _vm.topOffset + 'px' }),attrs:{"dir":_vm.direction}},[_c('div',{staticClass:"scrubber-shape"},[_c('div',{staticClass:"control-button",class:_vm.PLAY_BUTTON_CLASS},[_c('ScrubberPlayButton')],1),_vm._v(" "),_c('Highlight',{attrs:{"direction":_vm.direction,"locator":_vm.currentLocator,"word-element":_vm.currentWordElement}}),_vm._v(" "),_c('span',{staticClass:"text-cover"}),_vm._v(" "),_c('div',{staticClass:"handle-wrapper"},[_c('div',{ref:"scrubberHandler",staticClass:"handle",on:{"mousedown":_vm.grabScrubber}},[_c('BaseSpriteIcon',{attrs:{"custom-class":"wide-arrow","icon-name":"ico-scrubber-move-icon-up-down"}}),_vm._v(" "),_c('BaseSpriteIcon',{attrs:{"custom-class":"narrow-arrow arrow-up","icon-name":"ico-book-info-read-solid"}}),_vm._v(" "),_c('BaseSpriteIcon',{attrs:{"custom-class":"narrow-arrow arrow-down","icon-name":"ico-book-info-read-solid"}})],1)]),_vm._v(" "),_c('div',{staticClass:"scrubber-shade"})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }