<template>
  <audio
    :ref="audioElementId"
    preload="auto"
    :src="src"
    :data-has-errors="Boolean(error)"
    @error="audioErrorHandler"
    @canplaythrough="onAudioElementCanPlay"
  />
</template>

<script>
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import CustomErrorEnum from '@/enums/CustomErrorEnum';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('AudioWrapper.vue');

const errorTypeEnum = {
  MEDIA_ERR_ABORTED: 'MEDIA_ERR_ABORTED',
  MEDIA_ERR_NETWORK: 'MEDIA_ERR_NETWORK',
  MEDIA_ERR_DECODE: 'MEDIA_ERR_DECODE',
  MEDIA_ERR_SRC_NOT_SUPPORTED: 'MEDIA_ERR_SRC_NOT_SUPPORTED',
  UNSUPPORTED_ERR: 'UNSUPPORTED_ERR'
};

const audioErrorCodeMap = {
  1: errorTypeEnum.MEDIA_ERR_ABORTED,
  2: errorTypeEnum.MEDIA_ERR_NETWORK,
  3: errorTypeEnum.MEDIA_ERR_DECODE,
  4: errorTypeEnum.MEDIA_ERR_SRC_NOT_SUPPORTED,
  100: errorTypeEnum.UNSUPPORTED_ERR
};

const DEBOUNCE_LOAD_START_IOS = 100;

export default {
  props: {
    src: String
  },
  data() {
    return {
      error: null,
      canPlay: false,
      playbackRate: 0,
      audioElementId: 'audioElement'
    };
  },
  computed: {
    audioElement() {
      return this.$refs[this.audioElementId];
    },
    isIos() {
      return this.$store.getters['ContextStore/isIos'];
    },
    isIosBrowser() {
      return this.$store.getters['ContextStore/isIosBrowser'];
    }
  },
  watch: {
    src() {
      this.audioElement.dataset.canPlay = false;
      this.error = null;
    }
  },
  methods: {
    audioErrorHandler(event) {
      const defaultError = new Error('audio element error');
      defaultError.code = 100;
      const element = event.srcElement;
      const error = get(event, 'element.error', defaultError);
      const hasSource = element ? Boolean(element.getAttribute('src')) : false;
      if (!hasSource) {
        logger.warn('The audio source is not defined');
        return;
      }
      this.error = error;
    },
    checkErrors() {
      if (this.error) {
        this.$_audioErrorHandler(this.error);
        return true;
      }
      return false;
    },
    async play() {
      if (this.$_isAudioLoaded()) {
        await this.playAudio();
      } else {
        this.playOnElementReady();
      }
    },
    $_isAudioLoaded() {
      return this.audioElement.dataset.canPlay === 'true';
    },
    getDuration() {
      return this.audioElement.duration;
    },
    getCurrentTime() {
      return this.audioElement.currentTime;
    },
    async setCurrentTime(newTime) {
      if (this.$_isAudioLoaded()) {
        this.$_setCurrentTime(newTime);
      } else {
        await this.$_prepareAudioElement();
        this.$_setCurrentTime(newTime);
      }
    },
    $_setCurrentTime(newTime) {
      this.audioElement.currentTime = newTime;
    },
    async playOnElementReady() {
      try {
        await this.$_prepareAudioElement();
        await this.playAudio();
      } catch (error) {
        this.$_audioErrorHandler(error);
      }
    },
    $_audioErrorHandler(error) {
      const code = get(this.audioElement, 'error.code', null);
      const errorCode = get(error, 'code', null);
      const type = audioErrorCodeMap[code] || audioErrorCodeMap[errorCode];
      switch (type) {
        case errorTypeEnum.MEDIA_ERR_ABORTED:
          logger.warn(`get MEDIA_ERR_ABORTED for src:${this.src}`);
          break;
        case errorTypeEnum.MEDIA_ERR_DECODE:
          logger.warn(`get MEDIA_ERR_DECODE for src:${this.src}`);
          break;
        case errorTypeEnum.MEDIA_ERR_NETWORK:
        case errorTypeEnum.MEDIA_ERR_SRC_NOT_SUPPORTED:
        case errorTypeEnum.UNSUPPORTED_ERR:
          this.$emit('error', CustomErrorEnum.NETWORK_ERROR);
          break;
      }
    },
    $_prepareAudioElement() {
      return new Promise((resolve, reject) => {
        if (this.audioElement.error) {
          this.error = this.audioElement.error;
          reject(this.error);
        }
        if (this.audioElement) {
          if (this.isIos || this.isIosBrowser) {
            this.audioElement.addEventListener(
              'loadstart',
              debounce(() => {
                if (!this.audioElement) {
                  return;
                }
                this.audioElement.dataset.canPlay = true;
                resolve();
              }, DEBOUNCE_LOAD_START_IOS),
              {
                once: true
              }
            );
          } else {
            this.audioElement.addEventListener(
              'canplaythrough',
              () => {
                if (!this.audioElement) {
                  return;
                }
                this.audioElement.dataset.canPlay = true;
                resolve();
              },
              {
                once: true
              }
            );
          }

          this.audioElement.onerror = error => {
            this.error = error?.currentTarget?.error;
            reject(this.error);
          };
        }
        this.audioElement.load();
      });
    },
    pause() {
      if (
        this.audioElement &&
        (!this.audioElement.paused || !this.audioElement.ended)
      ) {
        this.audioElement.pause();
      }
    },
    playAudio() {
      this.audioElement.playbackRate = this.playbackRate;
      return this.audioElement.play();
    },
    onAudioElementCanPlay() {
      this.audioElement.dataset.canPlay = true;
    }
  }
};
</script>

<style></style>
