import ParagraphMaterialsFactory from '@/classes/factories/Materials/ParagraphMaterialsFactory';

export default {
  methods: {
    $_setItemMaterials(scrollItem) {
      const paraId = scrollItem.id;
      const itemMaterials = this.$_materialsLoader(paraId);
      scrollItem.paraMaterials = itemMaterials;
    },
    $_materialsLoader(paraId) {
      const store = this.$store;
      const bookId = this.materialsId;

      let materialsByParaId = store.getters[
        'MaterialsStore/getMaterialsByParaId'
      ](bookId, paraId);
      materialsByParaId.essayTask = (materialsByParaId.essayTask || []).map(
        task => {
          let essayByTaskId = store.getters[
            'MaterialsStore/getEssayTaskById' // here should be getter from study progress store
          ](bookId, task.id);
          return { ...task, ...essayByTaskId };
        }
      );
      materialsByParaId.flashcards = materialsByParaId.flashcards || [];
      materialsByParaId.quizes = materialsByParaId.quizes || [];
      materialsByParaId.paragraphNotes = materialsByParaId.paragraphNotes || [];
      return this.$_generateParagraphMaterials(materialsByParaId);
    },

    $_generateParagraphMaterials(materials) {
      const paragraphMaterials = ParagraphMaterialsFactory.getParagraphMaterials(
        materials
      );
      return paragraphMaterials;
    }
  }
};
