<template functional>
  <span :class="props.className">{{
    $options.methods.timeDurationFilter(props.duration, undefined, props.hours)
  }}</span>
</template>

<script>
import timeUtils from '@shared/utils/timeUtils';
import i18n from '@/i18n';

export default {
  name: 'TimeDuration',
  props: {
    duration: Number,
    className: String,
    hours: Boolean
  },
  methods: {
    timeDurationFilter(timeInMs, localizator = i18n.localize, hours) {
      const labelType = 'short';
      return timeUtils.timeDurationFilter(
        timeInMs,
        localizator,
        labelType,
        hours
      );
    }
  }
};
</script>
