<template>
  <div v-if="targetElement">
    <div
      ref="tooltip"
      class="tooltip"
      dir="auto"
      :style="tooltipPosition"
      :class="customClass"
    >
      <slot />
    </div>
    <PopupArrow
      v-if="showArrow"
      :align="align"
      :form-element="fromElement"
      :to-element="toElement"
      :x-offset="arrowXOffset"
      :y-offset="yOffset"
    />
  </div>
</template>

<script>
import PopupArrow from '@/components/base/PopupArrow/PopupArrow.vue';
import TooltipAlignEnum from '@/enums/TooltipAlignEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

const BORDER = 5;
const ARROW_HEIGHT = 16;

export default {
  components: {
    PopupArrow
  },
  props: {
    customClass: String,
    text: String,
    align: String,
    targetElement: [HTMLElement, SVGSVGElement],
    parentElement: {
      type: HTMLElement,
      required: false,
      default: null
    },
    fromElementClass: {
      type: String,
      required: false,
      default: ''
    },
    xOffset: {
      type: Number,
      required: false,
      default: 0
    },
    yOffset: {
      type: Number,
      required: false,
      default: 0
    },
    xOffsetBorder: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    if (this.targetElement) {
      this.init(this.targetElement);
    }
    let parentElementXOffset = 0;
    if (this.parentElement) {
      const clientRect = this.parentElement.getBoundingClientRect();
      parentElementXOffset = clientRect.left;
    }
    return {
      tooltipPosition: null,
      fromElement: null,
      toElement: null,
      arrowXOffset: 0,
      parentElementXOffset: parentElementXOffset
    };
  },
  computed: {
    showArrow() {
      return this.toElement && this.fromElement;
    }
  },
  watch: {
    targetElement(activator) {
      if (!activator) {
        return;
      }
      this.init(activator);
    },
    xOffset() {
      this.init(this.targetElement);
    }
  },

  methods: {
    init(activator) {
      this.$nextTick(() => {
        this.fromElement = this.fromElementClass
          ? document.querySelector(`.${this.fromElementClass}`)
          : this.$refs.tooltip;
        this.toElement = activator;
        this.arrowXOffset = this.$_calcArrowXOffset();
        this.tooltipPosition = this.alignTooltip(
          this.toElement,
          this.fromElement
        );
      });
    },
    alignTooltip(activator, tooltipElement) {
      const activatorClientRect = activator.getBoundingClientRect();
      const tooltipClientRect = tooltipElement.getBoundingClientRect();
      const bookScrollElement = document.querySelector(
        `.${AppConstantsUtil.SCROLL_LIST_CLASS}`
      );
      const bookScrollWidth = bookScrollElement
        ? bookScrollElement.offsetWidth
        : window.innerWidth;

      let top = this.yOffset + activatorClientRect.y;
      let left = this.$_calcLeft(activatorClientRect, tooltipClientRect);

      switch (this.align) {
        case TooltipAlignEnum.BOTTOM:
          top += activatorClientRect.height + ARROW_HEIGHT;
          break;
        case TooltipAlignEnum.TOP:
          top -= tooltipClientRect.height + ARROW_HEIGHT;
          break;
        default:
          break;
      }

      const rightEdge = left + tooltipClientRect.width;
      if (rightEdge > bookScrollWidth) {
        left -= rightEdge - bookScrollWidth + BORDER;
      }

      if (left < 0) {
        left = BORDER;
      }

      const viewPortBorders = this.$_getBorders();
      if (viewPortBorders.right < left + tooltipClientRect.width) {
        left = Math.round(
          viewPortBorders.right -
            tooltipClientRect.width -
            BORDER -
            this.xOffsetBorder
        );
      }

      if (viewPortBorders.left > left) {
        left = viewPortBorders.left;
      }

      let alignData = {
        left: left + 'px',
        top: top + 'px'
      };

      if (window.innerWidth < tooltipClientRect.width) {
        alignData.right = `${left}px`;
      }

      return alignData;
    },
    $_calcArrowXOffset() {
      return this.xOffset - this.parentElementXOffset;
    },
    $_calcLeft(activatorClientRect, tooltipClientRect) {
      let parentLeft = this.parentElementXOffset
        ? this.parentElementXOffset + BORDER
        : 0;
      const xOffset = this.xOffset || Math.round(activatorClientRect.width / 2);
      return (
        activatorClientRect.x +
        xOffset -
        Math.round(tooltipClientRect.width / 2) -
        parentLeft
      );
    },
    $_getBorders() {
      let left = BORDER;
      let right = window.innerWidth - BORDER;
      const viewPortBorders = {
        left,
        right,
        top: BORDER,
        bottom: window.innerHeight - BORDER
      };
      return viewPortBorders;
    }
  }
};
</script>
<style lang="less" scoped>
@import './Tooltip.less';
</style>
