import images from '@/assets/images';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('imageLoaderMixin.js');
const defImage = images.defaultBookImage;
export default {
  methods: {
    $_setImageSrc(element) {
      const previews =
        element.querySelectorAll('img:not([loaded]):not([in-progress])') || [];
      const promises = [];
      for (let i = 0; i < previews.length; i++) {
        const $preview = previews[i];
        // $preview.removeAttribute('height');
        // $preview.removeAttribute('width');
        promises.push(this.$_applyImage($preview));
      }
      if (promises.length) {
        return Promise.all(promises).then(() => {
          return element;
        });
      }
      return Promise.resolve(element);
    },
    $_applyImage(preview) {
      let dataSrc = preview.getAttribute('data-src');
      const isRealUrl = preview.src.indexOf('base64') === -1;
      if (!dataSrc || isRealUrl) {
        return Promise.resolve();
      }
      preview.setAttribute('in-progress', '');
      return this.$_imageLoader(dataSrc)
        .then(image => {
          return new Promise(function(resolve) {
            preview.setAttribute('src', image);
            preview.removeAttribute('in-progress');
            preview.setAttribute('loaded', '');
            preview.onload = function() {
              resolve();
            };
            preview.onerror = function(event = {}) {
              const error = new Error(
                `Get error on load dataSrc: ${dataSrc}, image: ${image}, ${event?.target}`
              );
              logger.warn(error);
              if (preview.getAttribute('src') !== defImage) {
                preview.setAttribute('src', defImage);
              }
              resolve();
            };
          });
        })
        .catch(error => logger.error(`Get error on load images ${error}`));
    },
    $_imageLoader(src) {
      if (!this.publicationId) {
        return Promise.reject('Did not get publicationId');
      }
      const imageInfo = {
        publicationId: this.publicationId,
        src
      };
      return this.$store.dispatch('BookStore/getImagePathByBookId', imageInfo);
    }
  }
};
