var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"vsl",staticClass:"scroll"},[_c('div',{class:[_vm.wclass],style:({
      display: 'block',
      'padding-top': _vm.paddingTop + 'px',
      'padding-bottom': _vm.paddingBottom + 'px',
      height: _vm.totalItems ? 'auto' : '100%'
    })},[(_vm.showMetaBlock)?_vm._t("meta-block"):_vm._e(),_vm._v(" "),(!_vm.scrollItems.length)?_vm._t("empty-placeholder"):_vm._e(),_vm._v(" "),_vm._l((_vm.scrollItems),function(item){return _c('div',{key:item.paragraph,class:[
        _vm.itemClassName,
        _vm.getAudioButtonClass(item.id),
        {
          [_vm.lastParaClass]: item.isLast,
          [_vm.lastParaWithSuggestedClass]:
            item.isLast && _vm.needShownSuggestedBooks,
          [_vm.firstParaClass]: item.isFirst
        }
      ],attrs:{"disabled":item.disabled,"dir":_vm.bookDirection,"item-index":item.index},on:{"dblclick":function($event){return _vm.onScrollItemClick($event, item)}}},[_c('div',{class:[_vm.itemWrapperClass, _vm.isPlaying],attrs:{"dir":item.direction}},[(item.isFirst)?_vm._t("prev-button"):_vm._e(),_vm._v(" "),_c('ParagraphMaterials',{attrs:{"para-materials-type":_vm.BEFORE_PARAGRAPH,"para-id":item.id},on:{"materialEvent":_vm.materialEventHandler}}),_vm._v(" "),(!item.isSection)?_vm._t("compilation-controls"):_vm._e(),_vm._v(" "),(!item.isSection)?_vm._o(_c('div',{staticClass:"book-content-item",domProps:{"innerHTML":_vm._s(item.paragraph)}}),0,item.paragraph):_c('SectionView',{attrs:{"book-id":_vm.publicationId,"para-id":item.id,"content":item.paragraph,"client-sort-id":item.clientSortId},on:{"hook:mounted":_vm.onMountedItem,"hook:destroyed":_vm.onDestroyedItem,"editControls":_vm.editHandler}}),_vm._v(" "),_c('ParagraphMaterials',{attrs:{"para-materials-type":_vm.AFTER_PARAGRAPH,"para-id":item.id},on:{"materialEvent":_vm.materialEventHandler}}),_vm._v(" "),(item.isLast)?_vm._t("next-button"):_vm._e(),_vm._v(" "),(_vm.needShownSuggestedBooks && item.isLast)?_c('SuggestedBooks'):_vm._e()],2),_vm._v(" "),(!_vm.isViewMode)?_vm._t("side-bar"):_vm._e()],2)}),_vm._v(" "),_vm._t("scrubber"),_vm._v(" "),_vm._t("text-selection")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }