export default {
  computed: {
    expandedFootnotesMode() {
      const readingSettingsView = this.$store.getters[
        'ReadingSettingsStore/getReadingSettingsView'
      ];
      return readingSettingsView.expandedFootnotesMode;
    }
  },
  methods: {
    $_applyFootnotesMode(isExpanded, container) {
      if (!container) {
        return;
      }
      const displayType = this.$_getDisplayType(isExpanded);
      const expandedSelector = this.$_getExpandedSelector(!isExpanded);
      const anchors = Array.prototype.slice.call(
        container.querySelectorAll(
          `a[epub\\:type="noteref"]${expandedSelector}`
        )
      );
      anchors.forEach(anchor => {
        this.$_displayFootnote(anchor, container, displayType);
      });
    },
    $_displayFootnote(anchor, container, displayType) {
      const selector = anchor.getAttribute('href');
      const isFootnoteTag = anchor.tagName === 'A';
      if (!selector || !isFootnoteTag) {
        return;
      }
      const footnote = container.querySelector(selector);
      if (!footnote) {
        return;
      }
      const isCurrentExpanded = footnote.style.display === 'block';

      const footnoteDisplayType = displayType
        ? displayType
        : this.$_getDisplayType(!isCurrentExpanded);

      footnote.style.display = footnoteDisplayType;
      const isExpandedNow = footnoteDisplayType === 'block';
      const attribute = this.$_getExpandedAttribute();
      if (isExpandedNow) {
        anchor.setAttribute(attribute, true);
      } else {
        anchor.removeAttribute(attribute);
      }
    },

    $_getDisplayType(isExpanded) {
      return isExpanded ? 'block' : 'none';
    },

    $_getExpandedAttribute() {
      return 'data-is-expand';
    },

    $_getExpandedSelector(isExpanded) {
      const attribute = this.$_getExpandedAttribute();
      return isExpanded ? `[${attribute}]` : `:not([${attribute}])`;
    }
  }
};
