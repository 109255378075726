<template>
  <div class="donation-block">
    <div class="donation-layout" :class="{ '-vertical': isVertical }">
      <div class="donation-content">
        <div class="content-title">{{ donationTitle }}</div>
        <div class="content-description" v-html="donationDescription" />
        <div class="content-controls">
          <DonationControl
            class="px-8"
            x-large
            color="#fff"
            :text-color="isOcean ? '#000' : 'var(--primary-color)'"
            :control-label="controlLabel"
          />
        </div>
      </div>
      <div class="donation-bg">
        <v-img
          width="100"
          :lazy-src="brandBg"
          :src="brandBg"
          alt="Donation background"
        />
      </div>
    </div>
  </div>
</template>

<script>
import images from '@/assets/images';
import DonationControl from '@/components/controls/DonationControl/DonationControl.vue';

import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
export default {
  name: 'DonationBlock',
  components: { DonationControl },
  props: {
    donationTitle: {
      type: String,
      default: ''
    },
    donationDescription: {
      type: String,
      default: ''
    },
    controlLabel: {
      type: String,
      default: 'Button'
    },
    isVertical: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    isOcean() {
      const brand = this.$store.getters['ContextStore/brand'];
      return brand === BrandsEnum.OCEAN;
    },
    brandBg() {
      return this.isOcean ? images.donationBgOcean : images.donationBgFfa;
    }
  },
  methods: {
    openDonationLink() {
      const userEmail = this.$store.getters['UserStore/getUserEmail'];
      const url = this.$store.getters['ContextStore/getDonationUrl'](userEmail);
      if (!url) {
        return;
      }
      this.$store.dispatch('ContextStore/openUrl', {
        url,
        openTarget: '_blank'
      });
    }
  }
};
</script>

<style scoped lang="less">
.donation-block {
  overflow: hidden;
}
.donation-layout {
  --pic-margin: 40px;
  background: var(--primary-color);
  color: var(--white-color);
  display: flex;
  justify-content: space-between;

  &.-vertical {
    flex-direction: column;

    .donation-bg {
      position: relative;
      margin-bottom: 10%;
      margin-inline-start: -6%;
      margin-inline-end: -15%;
    }
  }

  .donation-bg {
    display: flex;
    align-items: flex-end;
    user-select: none;
    height: calc(100% - var(--pic-margin) * 2);
    min-height: 290px;
    aspect-ratio: 1.065 / 1;
    width: auto;
    margin-top: var(--pic-margin);
    margin-bottom: var(--pic-margin);

    > * {
      height: 100%;
    }
  }

  .donation-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    padding: 24px;

    .content-title {
      font-size: 32px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: -0.04em;
    }

    .content-description {
      font-size: 16px;
      line-height: 24px;
      opacity: 0.8;
      min-height: 80px;
    }
  }

  .content-controls {
    button {
      color: var(--primary-color);
    }
  }
}
</style>
